import React, { FC, ReactNode } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styles from "./footer.module.scss";
import dashboard from "../../../shared/assets/icons/dashboard.svg";
import remoteMonitoring from "../../../shared/assets/icons/remote_monitoring.svg";
import deviceManagement from "../../../shared/assets/icons/device_management.svg";
import alerts from "../../../shared/assets/icons/alerts.svg";
import {
  Tooltip,
  styled,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";


interface FooterProps { }

interface CustomLinkProps {
  to: string;
  children?: ReactNode;
  // any props that come into the component
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "rgba(255, 255, 255)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

const Footer: FC<FooterProps> = () => (


  <div className={styles.Footer}>
    <CustomLink to="/dashboard">
      <div className={styles.icon}>
        <LightTooltip title="Dashboard" placement="top">
          <img className={styles.dashboardImg} src={dashboard} alt="" />
        </LightTooltip>

      </div>
    </CustomLink>
    <CustomLink to="/remoteMonitoring">
      <div className={styles.icon}>
        <LightTooltip title="Remote Monitoring" placement="top">
          <img
            className={styles.remoteMonitoringImg}
            src={remoteMonitoring}
            alt=""
          />
        </LightTooltip>

      </div>
    </CustomLink>
    <CustomLink to="/deviceManagement">
      <div className={styles.icon}>
        <LightTooltip title="Device Management" placement="top">
          <img className={styles.deviceManagementImg} src={deviceManagement} alt="" />
        </LightTooltip>

      </div>
    </CustomLink>
    <CustomLink to="/alerts">
      <div className={styles.icon}>
        <LightTooltip title="Alerts" placement="top">
          <img className={styles.alertsImg} src={alerts} alt="" />
        </LightTooltip>

      </div>
    </CustomLink>
  </div>

);


const CustomLink = ({ to, children, ...props }: CustomLinkProps) => {

  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <Link
      className={styles.nav_item + ` ${isActive ? styles.active : ""}`}
      to={to}
    >
      {children}
    </Link>
  );
};

export default Footer