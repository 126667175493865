import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../Auth';
import axiosClient from '../../common/apis/axiosClient';
import styles from './forgetPassword.module.scss';

interface LoginProps {}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const redirectPath = location.state?.path || '/auth/verificationLink';
  const redirectPath1 = location.state?.path || '/auth/error-message';
  
  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [enteredEmailTouched, setEnteredEmailTouched] =
    useState<boolean>(false);
  const [enteredPassword, setEnteredPassword] = useState<string>("");


  const validateEmail = (inputEmail: string): boolean => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    return regex.test(inputEmail);
  };

  const validatePassword = (password: string): boolean => {
    const trimmedPassword: string = password.trim();
    return trimmedPassword !== "" && trimmedPassword.length >= 8;
  };

  const enteredEmailIsValid: boolean = validateEmail(enteredEmail);
  const entetedEmailInputIsInvalid: boolean =
    !enteredEmailIsValid && enteredEmailTouched;

  const enteredPasswordIsValid: boolean = validatePassword(enteredPassword);

  let formIsValid: boolean = false;

  if (enteredEmailIsValid && enteredPasswordIsValid) {
    formIsValid = true;
  }

  const isEmailValid = enteredEmail.trim() !== '' && enteredEmail.includes('@');

  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEmail(event.target.value);
  };

  const emailInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setEnteredEmailTouched(true);
  };

  const auth = useAuth()

  const onSuccessSignIn = () => {
    axiosClient
      .post("api/forgotPassword", {
        email: enteredEmail,
        //password: enteredPassword,
      })
      .then((response) => {
        console.log("loginrespData", response);
       
        if (response.status === 200) {
          console.log("enterd");
          auth.login(response.data.user)
          navigate(redirectPath, {replace: true});
        }
      })
      .catch((err:any) => {
        console.log("nenterd");
        //auth.login(response.data.user)
        navigate(redirectPath1, {replace: true});
      });
  };


  return (
    <div className={styles.Login}>
      <form>
        <div>
          <Box className={styles.credBox}>
            <div className={styles.title}>
              <Typography variant="h3" className={styles.loginTxt}>
                Forgot Password
              </Typography>
              <Typography variant="body2" className={styles.captionTxt}>
                Please enter your email
              </Typography>
            </div>
            <div className={styles.credentials}>
              <div>
                <Typography variant="h5">Email</Typography>
                <TextField
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  margin="normal"
                  className={styles.emailBox}
                  onChange={emailChangeHandler}
                  onBlur={emailInputBlurHandler}
                  error={entetedEmailInputIsInvalid}
                  helperText={(entetedEmailInputIsInvalid && 'Not a valid Email') || " "}
                />
              </div>
            </div>
            <div className={styles.btn_verification} >
              <Button
                variant="contained"
                size="large"
                color="primary"
                name="loginBtn"
                onClick={onSuccessSignIn}
                className={styles.loginBtn}
                disabled = {!isEmailValid}
              >
                Send Verification Link
              </Button>
            </div>
            <div className={styles.forgot_pwd}>
            <a href="/">Back to Login Page</a>
            </div>
            <div className={styles.new_user}>
            <a href="/auth/register">New user? Register here</a>
            </div>
          </Box>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;