import axiosClient from "../../common/apis/axiosClient";

export function getAllDevices(): Promise<any> {
  return axiosClient.get("api/device");
}

export function getUserProfiles(): Promise<any> {
  return axiosClient.get(`api/userProfile`);
}

export function removeDashboardWidget(deviceId: string, readingType: string, widgetType: string): Promise<any> {
  return axiosClient.put(`api/deviceData/${deviceId}?readingType=${readingType}&widgetType=${widgetType}`);
}