import styles from "./widget-preference.module.scss";
import React, { FC, useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DashboardDataContext } from "../../context/DashboardDataProvider";
import axiosClient from "../../common/apis/axiosClient";
import { useQueryClient } from "@tanstack/react-query";

const WIDGETTYPES: any = {
  liveValue: "Live value",
  healthIndicator: "Health indicator",
  healthStats: "Health statistics",
};

interface WidgetPreferenceProps {}

const WidgetPreference: FC<WidgetPreferenceProps> = () => {

  const dashboardDataContext = useContext(DashboardDataContext);
  const [selectedWidget, setSelectedWidget] = useState("");
  const queryClient = useQueryClient();

  const closeWidgetPreferenceDialog = () => {
    dashboardDataContext.onCloseWidgetPreferenceDialog();
  };
  useEffect(() => {
    setSelectedWidget(dashboardDataContext.widgetPreferences.allowedWidgets[0]);
  }, []);

  const onWidgetTypeChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedWidget(event.target.value);
  };

   const onSaveWidgetPreference = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `${token}` },
      };
      const response = await axiosClient.get(`api/deviceData/${dashboardDataContext.widgetPreferences.deviceId}?readingType=${dashboardDataContext.widgetPreferences.signalName}&widgetType=${selectedWidget}`, config)
      if (response.status === 200) { 
        console.log("Added", dashboardDataContext.widgetPreferences);
        dashboardDataContext.onSaveWidgetPreference(selectedWidget);
        await queryClient.invalidateQueries({ queryKey: ["userProfiles"] });
      }
    }
    catch (err: any) {
      console.log("err", err);
    };
  };

  useEffect(() => {
    console.log("object", dashboardDataContext);
  }, []);

  return (
    <div className={styles.widgetDialog}>
      <div>
        <Dialog open={dashboardDataContext.showWidgetPreferenceDialog}>
          <DialogTitle>Widget preference</DialogTitle>
          <DialogContent>
            <DialogContentText variant="subtitle1"></DialogContentText>
            <div className={styles.deviceName}>
              <Typography paddingRight={"2rem"} paddingLeft={"0.5rem"}>
                Device: {dashboardDataContext.widgetPreferences.device}
              </Typography>
            </div>
            <div className={styles.signalName}>
              <Typography paddingRight={"2rem"} paddingLeft={"0.5rem"}>
                Signal: {dashboardDataContext.widgetPreferences.signalName}
              </Typography>
            </div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <div className={styles.widgetType}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel>Select widget type</InputLabel>
                  <Select
                    id="widgetType"
                    label="widgetType"
                    defaultValue={""}
                    onChange={onWidgetTypeChange}
                    className={styles.widgetTypeMenu}
                    value={dashboardDataContext.widgetPreferences.allowedWidgets['']}
                    key={WIDGETTYPES}
                  >
                    {dashboardDataContext.widgetPreferences.allowedWidgets.map((widgetType: any) => {
                      return <MenuItem value={widgetType} key={widgetType}>{WIDGETTYPES[widgetType]}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={closeWidgetPreferenceDialog}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSaveWidgetPreference}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default WidgetPreference;
