import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { RequiredAuth } from "../../RequiredAuth";
import Alerts from "../../components/alerts/Alerts";
import AssetTracker from "../../components/asset-tracker/asset-tracker";
import CreateAlert from "../../components/create-alert/create-alert";
import AddDevice from "../../components/device-management/add-device";
import DeviceManagement from "../../components/device-management/device-management";
import RemoteMonitoring from "../../components/remote-monitoring/remote-monitoring";
import AppHeader from "../../components/app-header/app-header";
import DashboardDataProvider from "../../context/DashboardDataProvider";
import Navigator from "../../components/navigator/navigator";
import { useQuery } from "@tanstack/react-query";
import { getAllDevices, getUserProfiles } from "./apis";
import { responseResolver } from "../../common/apis/axiosClient";
import { useDeviceStore, useUserProfileStore } from "../../common/store/app.store";
import Dashboard from "../../components/dashboard/dashboard";
import { useEffect } from "react";
import loadingGif from "../../shared/assets/icons/loading-load.gif"
import jwtDecode, { JwtPayload } from "jwt-decode";
import socketIOClient from "socket.io-client";
import './index.scss';

const ENDPOINT = "wss://app.vandyam.com";

const Layout = () => {
  return (
    <DashboardDataProvider>
      <AppHeader />
      <div className="wrapper">
        <div className="navigator">
          <Navigator />
        </div>
        <Outlet />
      </div>
    </DashboardDataProvider>
  );
};

const useDeviceListQuery = () => {
  const setDevices = useDeviceStore((state: any) => state.setDevices);

  //FIXME: get query key from constant file
  const result = useQuery(["devices"], responseResolver(getAllDevices), {
    onSuccess(response: any) {
      console.log("deviceListResponse", response);
      setDevices(response.devices);
    },
  });
  return result;
};

const Project = () => {
  const deviceListResult = useDeviceListQuery();
  const setWidgets = useUserProfileStore((state: any) => state.setWidgets);
  const setDeviceLiveData = useDeviceStore((state: any) => state.setDeviceLiveData);

  const userProfileResult = useQuery(["userProfiles"], responseResolver(getUserProfiles), {
    onSuccess(response) {
      console.log("[User Profiles]", response);
      const dashBoardData = response.user.devices[0].dashboard;
      const widgets = dashBoardData.reduce((acc: any, data: any) => {
        if (!acc[data.deviceId]) acc[data.deviceId] = [];
        acc[data.deviceId].push(data);
        return acc;
      }, {});
      setWidgets(widgets);
      dashBoardData.forEach((data: any) => {
        if (data.reading) setDeviceLiveData(data.reading);
      });
    },
  });

  useEffect(() => {
    console.log("[Project]");
    const socket = socketIOClient(ENDPOINT, {
      transports: ["websocket", "polling", "flashsocket"],
      upgrade: false,
      auth: {
        token: localStorage.getItem("token"),
      },
    });

    socket.on("connect", () => {
      console.log("inside socket connect");
      socket.emit("joinRoom", "abcd");
    });

    socket.on("disconnect", () => {
      console.log("inside socket disconnect");
    });

    socket.on(getID(), (data) => {
      if (typeof data !== "string") {
        console.log("socket data", data);
        setDeviceLiveData(data);
      }
    });
    return () => {
      console.log("inside useEffect cleanup function");
      socket.off("connect");
      socket.off("disconnect");
      socket.off(getID());
    };
  }, []);

  const getID = (): string => {
    const token = localStorage.getItem("token");
    if (!token) return "";
    const decodedToken: any = jwtDecode(token);
    const expirationTime = decodedToken?.exp as number;
    if (expirationTime < new Date().getTime()) {
      return decodedToken?.id as string;
    }
    return "";
  };


  if (deviceListResult.isInitialLoading && userProfileResult.isInitialLoading)
    return <div className="loading-container"><img src={loadingGif} alt="Loading" /></div>;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index path="dashboard" element={<Dashboard />} />
        <Route path="RemoteMonitoring" element={<RemoteMonitoring />} />
        <Route path="deviceManagement" element={<DeviceManagement />} />
        <Route path="alerts" element={<Alerts />} />
        <Route path="assetTracker" element={<AssetTracker />} />
        <Route path="createAlert" element={<CreateAlert />} />
        <Route path="addDevice" element={<AddDevice />} />
        <Route path="*" element={<Navigate to="dashboard" />} />
      </Route>
    </Routes>
  );
};

export default Project;
