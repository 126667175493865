import { useSyncExternalStore } from "react";
import { createReactiveStorage } from "../utils/createReactiveStorage";

export const reactiveStorage = createReactiveStorage();

export const useRefreshToken = () => {
  console.log('object');
  const refreshToken = useSyncExternalStore(reactiveStorage.subscribe, () => reactiveStorage.getItem("token"));
  return refreshToken;
};

export const useSetRefreshToken = () => {
  const setToken = (value: string) => reactiveStorage.setItem("token", value);
  return { clearToken: reactiveStorage.clear, setToken };
};
