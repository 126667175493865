import styles from "./widget.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useMemo, useState } from "react";
import { useDeviceStore } from "../../../common/store/app.store";
import { shallow } from "zustand/shallow";
import { useQueryClient } from "@tanstack/react-query";
import { removeDashboardWidget } from "../../../modules/project/apis";

interface WidgetProps {
  widgetId: string;
  widgetProps: any;
}

const Widget: FC<WidgetProps> = (props) => {
  const queryClient = useQueryClient();
  const [widgetRemoveInprogress, setWidgetRemoveInprogress] = useState(false);

  const { deviceId, readingType, widgetType } = props.widgetProps;
  //FIXME: separate device list from device data in zustand
  const device = useDeviceStore((state) => {
    if (!deviceId) {
      return null;
    }
    return state.deviceList.getDeviceById(deviceId);
  }, shallow);

  const liveData = useDeviceStore((state) => {
    if (!(deviceId && readingType && state.deviceLiveData[deviceId] && state.deviceLiveData[deviceId][readingType])) {
      return null;
    }
    return state.deviceLiveData[deviceId][readingType];
  });

  const { liveValue, rangeProps } = useMemo(() => {
    if (!liveData) {
      return { liveValue: "--", rangeProps: { color: "green", key: "Normal", values: [0, 0] } };
    }
    const rangeProps = device.getSignalRangeProp(readingType, liveData.reading);
    const value = liveData.reading.toFixed(1) + ` ${(liveData.readingUnit === "C" ? "°" : "") + liveData.readingUnit}`;
    return { liveValue: value, rangeProps };
  }, [liveData, device, readingType]);

  const onRemoveWidget = async () => {
    try {
      setWidgetRemoveInprogress(true);
      const response = await removeDashboardWidget(deviceId, readingType, widgetType);
      if (response.status === 200) {
        await queryClient.invalidateQueries({ queryKey: ["userProfiles"] });
        setWidgetRemoveInprogress(false);
      }
    } catch (error: any) {
      console.error(error);
      setWidgetRemoveInprogress(false);
    }
  };

  return (
    <>
      {
        <div className={styles.widget} style={{ opacity: widgetRemoveInprogress ? 0.5 : 1 }}>
          <div className={styles.left}>
            <span className={styles.title}>{props.widgetProps.readingType}</span>
            {props.widgetProps.widgetType === "liveValue" && (
              <span className={styles.status + ` ${styles[rangeProps.color]}`}>{liveValue}</span>
            )}
            {props.widgetProps.widgetType === "healthIndicator" && (
              <span className={styles.status + ` ${styles[rangeProps.color]}`}>{rangeProps.key}</span>
            )}
          </div>
          <div className={styles.right}>
            <div className={styles.cross_mark}>
              <CloseIcon onClick={onRemoveWidget} />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Widget;
