import React, { useEffect, FC, useRef, useState } from "react";
import axiosClient from "../../common/apis/axiosClient";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./healthStat.module.scss";
import { useQueryClient } from "@tanstack/react-query";
import { removeDashboardWidget } from "../../modules/project/apis";
var Plotly = require("plotly.js/dist/plotly.js");

interface HealthStatProps {
  widgetId: any;
  widgetProps: any;
}

const HealthStat: FC<HealthStatProps> = (props) => {
  const queryClient = useQueryClient();
  const [widgetRemoveInprogress, setWidgetRemoveInprogress] = useState(false);
  const { deviceId, readingType, widgetType } = props.widgetProps;
  const [week, setWeek] = React.useState<any>(7);

  var plotConfig = {
    responsive: true,
    scrollZoom: true,
    displaylogo: false,
    displayModeBar: false,
  };

  var layout = {
    autosize: true,
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    margin: { t: 50, l: 30, r: 30, b: 50 },
    xaxis: {
      title: "Time",
      titlefont: {
        family: "Inter",
        size: 14,
        color: "#5388D899",
      },
      tickfont: {
        color: "#00000061",
        size: 13,
      },
    },
    yaxis: {
      title: `${readingType}`,
      name: "Temperature",
      marker: "red",
      titlefont: {
        family: "Inter",
        size: 14,
        color: "#5388D899",
      },
      tickfont: {
        color: "#00000061",
        size: 13,
      },
    },
  };

  const handleChange = (event: any) => {
    setWeek(event.target.value);
  };

  useEffect(() => {
    getPlotDataFromServer();
  }, [week]);

  const getPlotDataFromServer = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };
    const response = await axiosClient.get(
      `/api/deviceData/${deviceId}?readingType=${readingType}&days=${week}&widgetType=healthStats`,
      config
    );
    console.log("[Health stat data]", response);
    let data = response.data.device
      .sort(function (a: any, b: any) {
        return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
      })
      .reduce(
        (acc: any, data: any) => {
          acc.x.push(data._id);
          acc.y.push(data.averageValue);
          return acc;
        },
        { x: [], y: [] }
      );
    let plotData = {
      ...data,
      type: "bar",
      marker: {
        color: "#9BA6BC",
      },
    };
    Plotly.newPlot("health_statistics_plot", [plotData], layout, plotConfig);
  };

  const buttonRef = useRef(null);

  const onRemoveWidget = async () => {
    try {
      setWidgetRemoveInprogress(true);
      const response = await removeDashboardWidget(deviceId, readingType, widgetType);
      if (response.status === 200) {
        await queryClient.invalidateQueries({ queryKey: ["userProfiles"] });
        setWidgetRemoveInprogress(false);
      }
    } catch (error: any) {
      console.error(error);
      setWidgetRemoveInprogress(false);
    }
  };
  
  return (
    <div className={styles.healthStatContainer} style={{ opacity: widgetRemoveInprogress ? 0.5 : 1 }}>
      <Typography
        className={styles.StatTitle}
        sx={{
          color: "#244791",
          fontWeight: "regular",
          fontSize: 20,
        }}
      >
        Health Statistics
      </Typography>
      <div className={styles.dropDown}>
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: 120,
            background: "white",
            borderRadius: "2px",
          }}
        >
          <InputLabel>Duration</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={week} onChange={handleChange}>
            <MenuItem value={7}>Last 1 Week</MenuItem>
            <MenuItem value={14}>Last 2 Week</MenuItem>
            <MenuItem value={21}>Last 3 Week</MenuItem>
          </Select>
        </FormControl>
        <IconButton onClick={onRemoveWidget} size="small" className={styles.statCloseButton}>
          <CloseIcon ref={buttonRef} />
        </IconButton>
      </div>
      <div className={styles.chart} id="health_statistics_plot" style={{ width: "100%" }}></div>
    </div>
  );
};

export default HealthStat;
