import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import MenuDots from './menuDots'
import axiosClient from '../../common/apis/axiosClient';
import styles from "./alerts.module.scss";
import searchBar from "../../shared/assets/icons/searchBar.svg"
import Button from '@mui/material/Button';
import { blueGrey, red } from '@mui/material/colors';
import { Grid } from '@mui/material';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function CustomPaginationActionsTable() {
  const [alertData, setAlertData] = React.useState([]);
  
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: { Authorization: `${token}` },
        };
        const getallAlerts = await axiosClient.get("api/alertNotification", config);
        console.log("getallAlerts", getallAlerts);
        setAlertData(getallAlerts.data.alertnotifications);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);
  
  const filterAlerts = (searchKey: any) => {
    setAlertData(alertData.filter((alert: any) => alert.message.toLowerCase().indexOf(searchKey.target.value.toLowerCase()) >= 0));
  }
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - alertData.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>

        <div className={styles.searchBar}>
          <img className={styles.searchBarImg} src={searchBar} alt="" />

            <input type="text" onChange={filterAlerts} placeholder="Search triggered alert name" />

        </div>
    
    <TableContainer component={Paper} sx={{p: 0 }}>
      <Table sx={{ minWidth: 50, p : 0 ,

      // '@media screen and (max-width: 48em)': {
      //   //display:Grid,
      //   flexDirection:'column',
      //   bg:'blueGrey',
      //   fontSize:12,
      // },

      // [`@media screen and (max-width: $sm)`]: {
      //   backgroundColor:red
      // },
      // '@media screen and (max-width: 48em)': {
      //   bg:red,
      //   fontSize:60,
      // },
      
      }}  aria-label="custom pagination table">
      <TableHead>
          <TableRow>
            <TableCell key={'date'} sx={{fontWeight: 'bold',fontSize: 16,}}>Date and Time</TableCell>
            <TableCell key={'alerts'} sx={{fontWeight: 'bold',fontSize: 16,
          '@media screen and (max-width: 48em)': {
            display:'none'
            },
            }}>Alerts</TableCell>
            <TableCell key={'value'} sx={{fontWeight: 'bold',fontSize: 16,
          '@media screen and (max-width: 48em)': {
            display:'none'
            },
          }}>Value</TableCell>
            {/* <TableCell align="right"></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? alertData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : alertData
          ).map((alertnotifications: any, _id :any) => (
            <TableRow 
            key = {alertnotifications._id}
            //key={alertData.emailSubject}
            >
              <TableCell key={alertnotifications.createdAt} sx={{fontSize: 16}} component="th" scope="row">
              {new Date(alertnotifications.createdAt).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })}
              <div className={styles.triggered_alert_msg}>
              Alert - {alertnotifications.message}
              </div>

              </TableCell>
              <TableCell key={alertnotifications.message} sx={{fontSize: 16,
              '@media screen and (max-width: 48em)': {
                display:'none'
                },
                 }} align="left" >
              {alertnotifications.message}
              </TableCell>
              <TableCell sx={{fontSize: 16,
              '@media screen and (max-width: 48em)': {
                display:'none'
                },
              }} align="left" ></TableCell>
              

              <TableCell  align="right"> 
             {/* <MenuDots id={alertnotifications._id} alertData={alertData} setAlertData={setAlertData} /> */}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={3} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={alertData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </div>
  );
 
}
