import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TrendConfigContext } from "../../context/TrendConfigProvider";
import { useContext } from "react";
import { DeviceDataContext } from "../../context/DeviceDataProvider";

interface RemoveSignalProps {
  axis: Array<any>;
  trend: string;
  handleClose: () => void;
}

const RemoveSignal: React.FC<RemoveSignalProps> = (props) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    props.handleClose();
  };

  const trendConfigContext = useContext(TrendConfigContext);
  const { removeDeviceSignal } = useContext(DeviceDataContext);

  const removeSignal = () => {
    for (let axis of props.axis) {
      removeDeviceSignal(
        trendConfigContext.trendConfig[props.trend][axis].device,
        trendConfigContext.trendConfig[props.trend][axis].signal
      );
      trendConfigContext.onTrendConfigChange(props.trend, axis, null, null);
    }
    props.handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to remove signal?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.axis.map((axis: any) => (
              <div key={axis}>{trendConfigContext.trendConfig[props.trend][axis].signal} </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              color: "white",
              backgroundColor: "#0275d8",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#24a0ed",
              },
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={removeSignal}
            sx={{
              color: "white",
              backgroundColor: "#0275d8",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#24a0ed",
              },
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RemoveSignal;
