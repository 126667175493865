import {TreeItem} from '@mui/x-tree-view';
import {TreeView}  from '@mui/x-tree-view';
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import axiosClient from "../../common/apis/axiosClient";
import { DashboardDataContext } from "../../context/DashboardDataProvider";
import styles from "./add-widget-panel.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import cross from "../../shared/assets/icons/cross.svg";
import plus from "../../shared/assets/icons/plus.svg";
import axios from "axios";
import { AppContext } from "../../context/AppContext";

interface AddWidgetPanelProps {}

const AddWidgetPanel: FC<AddWidgetPanelProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [allowedWidgetTypes, setAllowedWidgetTypes] = useState([]);
  const dashboardDataContext = useContext(DashboardDataContext);
  const appContext = useContext(AppContext);


  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    const filteredData = appContext.appdata.filter((device: any) => {
      return device.name.toLowerCase().includes(searchValue);
    });
    console.log("filteredData",filteredData)
    setDeviceData(filteredData);
  };  

  const openWidgetPreferenceDialog = (device: any, deviceId: any, signalName: any, allowedWidgets: any, range: any) => {
    dashboardDataContext.onShowWidgetPreferenceDialog(device, deviceId, signalName, allowedWidgets, range);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("appCOntext",appContext.appdata)
        if (appContext.appdata.length > 0) {
          setDeviceData(appContext.appdata);
          setAllowedWidgetTypes(appContext.appdata);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [appContext.appdata]);

  const closePanel = () => {
    dashboardDataContext.toggleAddWidgetPanel();
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.close}>
        <img className={styles.close_icon} onClick={closePanel} src={cross} alt="close" />
      </div>
      <div className={styles.main_wrapper}>
        <div className={styles.wrapper}>
          <TextField
            id="filled-hidden-label-small"
            defaultValue=""
            variant="filled"
            size="small"
            label="Search device name"
            onChange={onSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.tree_view}>
            {isLoading ? (
              <p style={{ color: "black" }}>Loading...</p>
            ) : (
              <TreeView
                aria-label="device selection"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={deviceData.map((devices: any) => {
                  return devices._id;
                })}
                sx={{
                  flexGrow: 1,
                  maxWidth: "15.625rem",
                  overflow: "hidden",
                }}
              >
                {deviceData.map((devices: any) => {
                   const deviceStatusStyle = devices.status === "active" ? styles.deviceStatusGreen : styles.deviceStatusRed;
                   console.log("devicesStatus",deviceStatusStyle)
                  return (
                  <Fragment key={devices._id}>
                    <TreeItem
                      nodeId={devices._id}
                      label={
                        <div className={styles.tree_item}>
                          {devices.name}
                          <div className={`${styles.statusIndicator} ${deviceStatusStyle}`} />
                        </div>
                      }
                    >
                      {devices.parameters.map((signals: any) => {
                        return (
                          <Fragment key={devices.name + signals.id}>
                            <TreeItem
                              sx={{
                                height: 40,
                              }}
                              onClick={() =>
                                openWidgetPreferenceDialog(
                                  devices.name,
                                  devices._id,
                                  signals.type,
                                  signals.allowedWidgetTypes,
                                  signals.range,
                                )
                              }
                              nodeId={devices.name + signals.type}
                              label={
                                <div className={`${styles.tree_item}`}>
                                  <span>{signals.type}</span>
                                  <span>
                                    <img className={styles.add} src={plus} alt="add" />
                                  </span>
                                </div>
                              }
                            ></TreeItem>
                          </Fragment>
                        );
                      })}
                    </TreeItem>
                  </Fragment>
                );

                })}
              </TreeView>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddWidgetPanel;
