import React, { FC, useContext } from "react";
import styles from "./axis-settings.module.scss";
import {
  Typography,
  Box,
  Popover,
  Button,
  createTheme,
  ThemeProvider,
  Tooltip,
  styled,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { TrendConfigContext } from "../../context/TrendConfigProvider";
import RemoveSignal from "../remove-signal-dialog/remove-signal-dialog";
import ExcelExport from "../DownloadData/ExcelExport"
import CSVExport from "../DownloadData/CSVExport"
import { AppContext } from "../../context/AppContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
});

interface AxisSettingsProps {
  axis: string;
  trend: string;
  id: string | undefined;
  open: boolean;
  showAxis1: any;
  positionX: number;
  positionY: number;
  onClose:
  | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
  | undefined;
  axisColorChangeHandler: (trend: string, axis: string, color: string) => void;
  axisScaleChangeHandler: (trend: string, axis: string, scale: string) => void;
}
const AxisSettings: FC<AxisSettingsProps> = (props) => {
  const trendConfigContext = useContext(TrendConfigContext);
  const appContext = useContext(AppContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let firstArray = []
  let secondArray: never[] = []
  let sig: any

  const getAxisDeviceName = (trendNumber:number, axisNumber:number, appdata:any, trendConfigContext:any) => {
    const trendConfig = trendConfigContext.trendConfig[`trend${trendNumber}`];
    const deviceId = trendConfig[`axis${axisNumber}`]?.device;
    const deviceName = appdata.find((device:any) => device?._id === deviceId)?.name;
    return deviceName;
  };
  
const axes = ['axis1', 'axis2', 'axis3', 'axis4'];
const trends = ['trend1', 'trend2', 'trend3', 'trend4'];

if (props.trend && props.axis && trends.includes(props.trend) && axes.includes(props.axis)) {
  const trendData = trendConfigContext.trendConfig[props.trend][props.axis].data;
  firstArray = trendData.x;
  secondArray = trendData.y;
  sig = trendConfigContext.trendConfig[props.trend][props.axis].signal;
}
var arrayOfExcelObject = firstArray.map(function (value: any, index: any) {
    var excelObject: any = { "Created At (Time)": value };

    if (props.trend && props.axis && trends.includes(props.trend) && axes.includes(props.axis)) {
        const selectedAxisDeviceName = getAxisDeviceName(trends.indexOf(props.trend) + 1, axes.indexOf(props.axis) + 1, appContext.appdata, trendConfigContext);
        const selectedSignal = trendConfigContext.trendConfig[props.trend][props.axis].signal;

        excelObject[`${selectedAxisDeviceName}_${selectedSignal}`] = secondArray[index];
    }

    return excelObject;
});
var arrayOfCSVObject = firstArray.map(function (value: any, index: any) {
  if (props.trend && props.axis && trends.includes(props.trend) && axes.includes(props.axis)) {
      const selectedAxisDeviceName = getAxisDeviceName(trends.indexOf(props.trend) + 1, axes.indexOf(props.axis) + 1, appContext.appdata, trendConfigContext);
      const selectedSignal = trendConfigContext.trendConfig[props.trend][props.axis].signal;

      return { "Created At (Time)": value, [`${selectedAxisDeviceName}_${selectedSignal}`]: secondArray[index] };
  }

  return { "Created At (Time)": value };
});

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255)",
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const removeSignalDialog = () => {
    return (
      <div>
        {open && (
          <RemoveSignal
            handleClose={handleClose}
            axis={[props.axis]}
            trend={props.trend}
          />
        )}
      </div>
    );
  };

  return (
    <div className={styles.AxisSettings}>
      <div>
        <ThemeProvider theme={theme}>
          <Popover
            id={props.id}
            open={props.open}
            onClose={props.onClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: props.positionY, left: props.positionX }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div>
              <Box
                alignItems={"center"}
                justifyContent="center"
                sx={{
                  width: "12.5rem",
                  height: "22rem",
                  backgroundColor: "#082253",
                  borderRadius: "0.3125rem",
                }}
              >
                <Typography
                  textAlign="center"
                  padding={"1rem"}
                  sx={{
                    /* identical to box height */
                    fontWeight: "900",
                    color: "white",
                  }}
                >
                  Y-Axis Settings
                </Typography>

                <Typography
                  // textAlign="center"
                  paddingLeft={"1rem"}
                  paddingTop={"0rem"}
                  sx={{
                    fontWeight: "900",
                    fontSize: "15px",
                    color: "white",
                  }}
                >
                  Scale
                </Typography>
                <div>
                  <Button
                    variant="text"
                    onClick={() =>
                      props.axisScaleChangeHandler(
                        props.trend,
                        props.axis,
                        "linear"
                      )
                    }
                    color="primary"
                  >
                    LINEAR
                  </Button>
                  <Button
                    variant="text"
                    onClick={() =>
                      props.axisScaleChangeHandler(
                        props.trend,
                        props.axis,
                        "log"
                      )
                    }
                    color="primary"
                  >
                    LOG
                  </Button>
                </div>

                <Typography
                  className={styles.colorText}
                  paddingLeft={"1rem"}
                  paddingTop={"1em"}
                  sx={{
                    fontWeight: "900",
                    fontSize: "15px",
                    color: "white",
                  }}
                >
                  Color
                </Typography>
                <Box display="flex" justifyContent="space-between">
                  <LightTooltip title="Purple" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#9400D3"
                        )
                      }
                      sx={{
                        marginLeft: "5px",
                        backgroundColor: "#9400D3",
                        minWidth: "5px",
                        minHeight: "25px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#9400D3",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                  <LightTooltip title="Violet" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#4B0082"
                        )
                      }
                      sx={{
                        backgroundColor: "#4B0082",
                        minWidth: "5px",
                        minHeight: "25px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#4B0082",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                  <LightTooltip title="Blue" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#0000FF"
                        )
                      }
                      sx={{
                        backgroundColor: "#0000FF",
                        minWidth: "5px",
                        minHeight: "25px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#0000FF",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                  <LightTooltip title="Green" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#00FF00"
                        )
                      }
                      sx={{
                        backgroundColor: "#00FF00",
                        minWidth: "5px",
                        minHeight: "25px",
                        marginRight: "5px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#00FF00",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  paddingTop={"10px"}
                >
                  <LightTooltip title="Yellow" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#FFFF00"
                        )
                      }
                      sx={{
                        marginLeft: "5px",
                        backgroundColor: "#FFFF00",
                        minWidth: "5px",
                        minHeight: "25px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#FFFF00",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                  <LightTooltip title="Orange" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#FF7F00"
                        )
                      }
                      sx={{
                        backgroundColor: "#FF7F00",
                        minWidth: "5px",
                        minHeight: "25px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#FF7F00",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                  <LightTooltip title="Red" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#FF0000"
                        )
                      }
                      sx={{
                        backgroundColor: "#FF0000",
                        minWidth: "5px",
                        minHeight: "25px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#FF0000",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                  <LightTooltip title="White" placement="bottom">
                    <Button
                      variant="contained"
                      onClick={() =>
                        props.axisColorChangeHandler(
                          props.trend,
                          props.axis,
                          "#FFFFFF"
                        )
                      }
                      sx={{
                        backgroundColor: "#FFFFFF",
                        minWidth: "5px",
                        minHeight: "25px",
                        marginRight: "5px",
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "#FFFFFF",
                        },
                      }}
                    ></Button>
                  </LightTooltip>
                </Box>
                <Typography
                  paddingLeft={"1rem"}
                  paddingTop={"1rem"}
                  sx={{
                    fontWeight: "900",
                    fontSize: "15px",
                    color: "white",
                  }}
                >
                  Download
                </Typography>
                <div>
                  <ExcelExport excelData={arrayOfExcelObject} fileName={"ExcelData"} />

                  <CSVExport csvData={arrayOfCSVObject} fileName={"CSVData"} />
                </div>
                <div className={styles.signalBtn}>
                  <Button
                    onClick={handleClickOpen}
                    variant="outlined"
                    color="primary"
                  >
                    Remove the Signal
                  </Button>
                  <div>{open && removeSignalDialog()}</div>
                </div>
              </Box>
            </div>
          </Popover>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default AxisSettings;
