import React, { FC, useState } from "react";
import styles from "./settings.module.scss";
import { Typography, Box, Popover, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth";
import { Settings } from "@mui/icons-material";
import { useSetRefreshToken } from "../../hooks/useRefreshToken";

interface SettingsProps {
  onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined;
  id: string | undefined;
  open: boolean;
  positionX: number;
  positionY: number;
}

const SettingsPopOver: FC<SettingsProps> = (props) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [open, setOpen] = useState(true);
  const { clearToken } = useSetRefreshToken();

  const logout = () => {
    clearToken();
    auth.logout();
  };
  
  return (
    <div>
      <Popover
        id={props.id}
        open={open}
        onClose={props.onClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: props.positionY, left: props.positionX }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2, backgroundColor: "white" }}>OPTIONS</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button onClick={logout} startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

export default SettingsPopOver;
