import React, { FC, useEffect, useState, useContext } from "react";
import DeviceSelection from "../device-selection/device-selection";
import styles from "./remote-monitoring.module.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeviceDataProvider, { DeviceDataContext } from "../../context/DeviceDataProvider";
import TrendPlot from "../trend-plot/trend-plot";
import TrendPlotDataProvider, { TrendPlotDataContext } from "../../context/TrendPlotDataProvider";
import TrendConfigProvider, { TrendConfigContext } from "../../context/TrendConfigProvider";
import TrendDataReader from "../trend-data-reader/trend-data-reader";
import SignalDialog from "../signal-selection/signal-selection";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import Footer from "../dashboard/widget/Footer";

interface RemoteMonitoringProps {}

const RemoteMonitoring: FC<RemoteMonitoringProps> = () => {
  const [deviceSelectionPanel, setDeviceSelectionPanel] = useState<boolean>(true);
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255)",
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  return (
    <DeviceDataProvider>
      <div className={styles.main_container}>
        <TrendConfigProvider>
          <div className={styles.RemoteMonitoring}>
            <div className={styles.plotContainer}>
              <TrendDataReader></TrendDataReader>
            </div>
          </div>
          <div className={styles.sideBar}>
            <div className={styles.panel_toggle} onClick={() => setDeviceSelectionPanel((state) => !state)}>
              {deviceSelectionPanel ? (
                <LightTooltip title="Collapse Side Panel" placement="bottom">
                  <ChevronRightIcon />
                </LightTooltip>
              ) : (
                <LightTooltip title="Expand  Side Panel" placement="left">
                  <ChevronLeftIcon />
                </LightTooltip>
              )}
            </div>
            {deviceSelectionPanel && (
              <div className={styles.deviceSelection}>
                <DeviceSelection></DeviceSelection>
              </div>
            )}
          </div>
          <div className={styles.Footer}>
          <Footer />
          </div>
          <SignalDialog />
        </TrendConfigProvider>
        <div className={styles.Footer}>
          {" "}
          <Footer />
          {" "}
        </div>
      </div>
    </DeviceDataProvider>
  );
};

export default RemoteMonitoring;
