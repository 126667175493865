import React, { FC, ReactNode, useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import styles from "./navigator.module.scss";
import dashboard from "../../shared/assets/icons/dashboard.svg";
import remoteMonitoring from "../../shared/assets/icons/remote_monitoring.svg";
import deviceManagement from "../../shared/assets/icons/device_management.svg";
import alerts from "../../shared/assets/icons/alerts.svg";
import mapMarker from "../../shared/assets/images/map-marker.svg"
import axios from "axios";
import version from "../../version.json"

interface NavigatorProps {}

interface CustomLinkProps {
  to: string;
  children?: ReactNode;
  // any props that come into the component
}

const Navigator: FC<NavigatorProps> = () => {
  const [appVersion, setAppVersion] = useState("");

  useEffect(() => {
    setAppVersion(version.version); // Set the app version from the version.json file
  }, []);

  return (
    <div className={styles.Navigator}>
      <div className={styles.container}>
        <CustomLink to="/dashboard">
          <div className={styles.icon}>
            <img className={styles.dashboardImg} src={dashboard} alt="" />
          </div>
          <div className={styles.text}>DASHBORD</div>
        </CustomLink>
        <CustomLink to="/remoteMonitoring">
          <div className={styles.icon}>
            <img className={styles.remoteMonitoringImg} src={remoteMonitoring} alt="" />
          </div>
          <div className={styles.text}>REMOTE MONITORING</div>
        </CustomLink>
        <CustomLink to="/deviceManagement">
          <div className={styles.icon}>
            <img className={styles.deviceManagementImg} src={deviceManagement} alt="" />
          </div>
          <div className={styles.text}>DEVICE MANAGEMENT</div>
        </CustomLink>
        <CustomLink to="/alerts">
          <div className={styles.icon}>
            <img className={styles.alertsImg} src={alerts} alt="" />
          </div>
          <div className={styles.text}>ALERTS</div>
        </CustomLink>
        <CustomLink to="/assetTracker">
          <div className={styles.icon}>
            <img className={styles.assetImg} src={mapMarker} alt="asset_tracker" />
          </div>
          <div className={styles.text}>ASSET TRACKER</div>
        </CustomLink>
      </div>
      <div className={styles.version} >  
       <span style={{fontSize:"0.8rem"}}>{appVersion}</span>
       </div> 
    </div>
  );
};

const CustomLink = ({ to, children, ...props }: CustomLinkProps) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <Link className={styles.nav_item + ` ${isActive ? styles.active : ""}`} to={to}>
      {children}
    </Link>
  );
};

export default Navigator;

