import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import styles from "./device-management.module.scss";
import searchBar from "../../shared/assets/icons/searchBar.svg"
import axiosClient from '../../common/apis/axiosClient';
import { Alert, AlertColor, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from '@mui/material';
import Footer from "../dashboard/widget/Footer";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppContext } from '../../context/AppContext';
import { IDevice, ISignal } from "../../common/modal/device.modal";


interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (



    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {
  const [deviceData, setDeviceData] = useState([]);
  const appContext = useContext(AppContext);
  const [rename, setRename] = useState<any>('');
  const [deviceID, setDeviceID] = useState<any>('');
  const [deviceName, setDeviceName] = useState<any>('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [helperTextRename, setHelperTextRename] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogContext, setDialogContext] = useState<any>('');
  const [addDeviceDialogBox, setAddDeviceDialogBox] = useState(false);
  const [signaldropDown, setSignalDropDown] = useState<any>([]);
  const [selectedDevice, setSelectedDevice] = useState<any>('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<any>('');
  const [renameDeviceDialog, setRenameDeviceDialog] = useState<any>(false);
  const ButtonRef = useRef<any>(null);
  const cancelButtonRef = useRef<any>(null);
  let severity: AlertColor = 'warning';

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `${token}` },
      };
      const getallDevices = await axiosClient.get("api/device", config);
      console.log("getallDevices", getallDevices);
      setDeviceData(getallDevices.data.devices);
      localStorage.setItem("getallDevices", JSON.stringify(getallDevices.data.devices));
    } catch (error) {
      console.log("error", error);
    }
  };


  useEffect(() => {
    fetchData();

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        ButtonRef?.current?.click();
      }

      if (event.key === 'Escape') {
        event.preventDefault();
        cancelButtonRef?.current?.click();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const filterAlerts = (searchKey: any) => {
    setDeviceData(JSON.parse(localStorage.getItem('getallDevices') || '{}').filter((device: any) => device.name.toLowerCase().indexOf(searchKey.target.value.toLowerCase()) >= 0));
  }


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deviceData.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const sopen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, deviceId: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedDevice(deviceId);
  };

  const handleRemoveDevice = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `${token}` },
    };

    try {
      const response = await axiosClient.put('/api/userProfile', {
        deviceId: selectedDevice,
        remove: true,
      }, config);
      if (response.status === 200) {
        console.log('Device deleted successfully', response);
        setAnchorEl(null);
        setShowToast(true);
        setToastMessage('Device Deleted Successfully')
        fetchData();
      }
    } catch (error) {
      console.error('Deleting device failed', error);
      setAnchorEl(null);
      setShowToast(true);
      setToastMessage('Not able to deleted device')
    }
  };

  const renameDevice = async () => {
    if (!rename) {
      setError(true);
      setHelperTextRename('Please enter name');
      return;
    }
    setRenameDeviceDialog(false)
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `${token}` },
    };
    try {
      const response = await axiosClient.put(`/api/device/${selectedDevice}`, {
        name: rename,
      }, config);
      if (response.status === 200) {
        setShowToast(true);
        setToastMessage('Device Renamed Successfully')
        setAnchorEl(null);
        fetchData();
      }
    } catch (error) {
      console.error('Error renaming device', error);
      setShowToast(true);
      setToastMessage('Renaming Failed')
      setAnchorEl(null);
      setOpenDialog(true);
    }
  };

  const RenameDeviceDialog = () => {
    setAnchorEl(null);
    setRenameDeviceDialog(true)
    setRename('');
    setError(false);
    setHelperTextRename('');
  }

  const options = [
    { label: 'Rename', action: RenameDeviceDialog },
    { label: 'Delete', action: handleRemoveDevice },
  ];

  const handleRenameField = (e: { target: { value: any; }; }) => {
    setRename(e.target.value)

    if (rename) {
      setError(false);
      setHelperTextRename('');
    }
  }


  const handleDeviceIDField = (e: { target: { value: any; }; }) => {
    const input = e.target.value;
    setDeviceID(input);
  
    if (input.trim() !== "") {
      if (error) {
        setError(false);
        setHelperText("");
      }
    } else {
      setError(true);
      setHelperText("Please enter a device ID");
    }
  }

  const handleDeviceNameField = (e: { target: { value: any; }; }) => {
    setDeviceName(e.target.value)
  }


  const handleAddDevice = async () => {
    if (!deviceID) {
      setError(true);
      setHelperText('Please enter a device ID');
      return;
    }
    setAddDeviceDialogBox(false)

    const alreadyExist = deviceData.map((device: any) => {
      return device._id;
    })
    const alreadyExists = alreadyExist.includes(deviceID);
    if (alreadyExists) {
      setShowToast(true);
      setToastMessage('Device Already Exist')
      setAnchorEl(null);
      return;
    }

    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `${token}` },
    };
    try {
      const response = await axiosClient.put('/api/userProfile', {
        deviceId: deviceID,
        name: deviceName,
      }, config);

      const signalList = response.data.device.parameters.map((parameter: any) => {
        return parameter.type
      })

      if (response.status === 200) {
        console.log('Device Added Successfully', response);
        setDialogMessage("Device Added Successfully");
        setDialogContext(`The available signals are:`)
        setSignalDropDown(signalList);
        setOpenDialog(true);
        fetchData();
      }
    } catch (error) {
      console.error('Error adding device', error);
      setDialogMessage("Error Adding Device");
      setDialogContext('Enter a valid Device ID.')
      setOpenDialog(true);
    }
  };

  const handleBlur = () => {
    if (!deviceID) {
      setError(true);
      setHelperText('Please enter a device ID');
    }
    if (!rename) {
      setError(true);
      setHelperTextRename('Please enter name');
    }
    else {
      setError(false);
      setHelperText('');
      setHelperTextRename('');
    }
  };

  const handleClearInput = () => {
    setDeviceID('');
    setError(false);
    setHelperText('');
    setHelperTextRename('');
  };

  const handleOkClick = () => {
    setOpenDialog(false);
    setAddDeviceDialogBox(false);
  };

  const addDeviceDialog = () => {
    setAddDeviceDialogBox(true)
    setDeviceID('');
    setError(false);
    setHelperText('');
  }


  if (toastMessage === 'Device Renamed Successfully') {
    severity = 'success';
  } else if (toastMessage === 'Device Deleted Successfully') {
    severity = 'error';
  } else if (toastMessage === 'Device Already Exist') {
    severity = 'warning';
  }

  return (
    <div className={styles.main_container}>
      <div className={styles.wrapper}>
        <div className={styles.deviceManagement}>
          <div className={styles.btn}>
            <Button sx={{ fontWeight: 'bold' }}
              variant="contained" onClick={addDeviceDialog} className={styles.add_button}>
              Add Device
            </Button>
          </div>
          <div className={styles.searchBar}>
            <img className={styles.searchBarImg} src={searchBar} alt="" />
            <input type="text" onChange={filterAlerts} placeholder="Search alert name" />
          </div>

          <div>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 50 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <TableCell key="device" sx={{
                    fontWeight: 'bold', fontSize: 16, minWidth: 200,
                    '@media screen and (max-width: 48em)': {
                      minWidth: 20,
                      fontSize: 25
                    }
                  }}>Device</TableCell>
                  <TableCell key="signals" sx={{
                    fontWeight: 'bold', fontSize: 16, minWidth: 200,
                    '@media screen and (max-width: 48em)': {
                      display: 'none'
                    },
                  }}>Signals</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? deviceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : deviceData
                  ).map((device: any, _id: any) =>{
                    const deviceStatusStyle = device.status === "active" ? styles.deviceStatusGreen : styles.deviceStatusRed;

                  return(

                    <TableRow
                    key={device._id}
                    //key={alertData.emailSubject}
                    >
                      <TableCell key={device.name} sx={{
                        fontSize: 16,
                        '@media screen and (max-width: 48em)': {
                          minWidth: 20, 
                          fontWeight: 'bold',
                          fontSize: 25
                        }
                      }} component="th" scope="row">
                       
                       <div className={styles.tree_item}>
                        
                        <div className={`${styles.statusIndicator} ${deviceStatusStyle}`}></div>
                        <span className={styles.label}>{device.name}</span>
                      </div>
                        <div className={styles.signalDisc}>
                          <span>{device.parameters.map(function (parameter: any) { return parameter.type; }).join(', ')}</span>
                        </div>
                      </TableCell>
                      <TableCell key={device.parameters} sx={{
                        fontSize: 16,
                        '@media screen and (max-width: 48em)': {
                          display: 'none'
                        },
                      }} align="left" >
                        <span>{device.parameters.map(function (parameter: any) { return parameter.type; }).join(', ')}</span>
                      </TableCell>
                      <TableCell align="right">

                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={sopen ? 'long-menu' : undefined}
                            aria-expanded={sopen ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, device._id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={sopen}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            {options.map((option) => (
                              <MenuItem key={option.label} onClick={() => option.action()}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  )})
                  }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter >
                  <TableRow  >
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={deviceData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className={styles.Footer}>
        {" "}<Footer />{" "}
      </div>

      <Dialog open={addDeviceDialogBox}>
        <DialogTitle>ADD DEVICE</DialogTitle>
        <DialogContent>
          <div className={styles.dialogContent}>
            <div className={styles.deviceID}>Device ID:</div>
            <TextField
              variant="standard"
              placeholder="Enter deviceID"
              sx={{ width: 250 }}
              value={deviceID}
              onChange={handleDeviceIDField}
              onBlur={handleBlur}
              error={error}
              helperText={helperText}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="clear input"
                    onClick={handleClearInput}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }}
            />
          </div>
          <div className={styles.dialogContent}>
            <div className={styles.deviceName}>Device Name:</div>
            <TextField
              variant="standard"
              placeholder="Enter name (optional)"
              sx={{ width: 250 }}
              value={deviceName}
              onChange={handleDeviceNameField}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="clear input"
                    onClick={handleClearInput}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDeviceDialogBox(false)} ref={cancelButtonRef}>
            Cancel
          </Button>
          <Button onClick={handleAddDevice} ref={ButtonRef}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={severity}>{toastMessage}</Alert>
      </Snackbar>

      <Dialog open={openDialog}>
        <DialogTitle>{dialogMessage}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContext}
          </DialogContentText>
          <ul>
            {signaldropDown.map((item: any, index: any) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkClick} ref={ButtonRef}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={renameDeviceDialog}>
        <DialogTitle>RENAME DEVICE</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            placeholder="Enter name"
            sx={{
              width: 250
            }}
            value={rename}
            onChange={handleRenameField}
            onBlur={handleBlur}
            error={error}
            helperText={helperTextRename}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="clear input"
                  onClick={handleClearInput}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              )
            }}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameDeviceDialog(false)} ref={cancelButtonRef}>
            Cancel
          </Button>
          <Button onClick={renameDevice} ref={ButtonRef}>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
