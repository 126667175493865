import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AppContextProvider from "./context/AppContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <AppContextProvider>
    <QueryClientProvider client={queryClient}>
      <App />
      {/* <ReactQueryDevtools/> */}
    </QueryClientProvider>
  </AppContextProvider>
);
