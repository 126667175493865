import React, { useEffect, useState } from "react";

interface IProps {
  children: React.ReactNode;
}

interface ICheckboxDetails {
  deviceId: string;
  deviceName: string;
  signalId: string;
  signalName: string;
}

export const DeviceDataContext = React.createContext({
  allDeviceDataCtx: [],
  updateDeviceData: (data: any) => {},
  onTrendShowPlot: (data: boolean) => {},
  showTrendPlot: false,
  showPreferenceDialogBox: false,
  handlePreferenceDialogBox: (data: boolean) => {},
  checkBoxSelectedSignals: [] as string[],
  onInputCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement> | null,
    deviceId: string,
    deviceName: string,
    signalId: string,
    signalName: string
  ) => {},
  removeDeviceSignal: (deviceId: string, signalName: string) => {},
  checkBoxCheckedData: {} as ICheckboxDetails,
});

const DeviceDataProvider = ({ children }: IProps) => {
  const [deviceData, setDeviceData] = useState<any>([]);
  const [showPlot, setShowPlot] = useState<boolean>(false);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [selectedSignalsCheckbox, setSelectedSignalsCheckbox] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("selectedSignalsCheckboxSavedData");
    if (saved) {
      return JSON.parse(saved);
    }
    return [];
  });
  const [checkedDeviceDetails, setCheckedDeviceDetails] = useState({
    deviceId: "",
    deviceName: "",
    signalId: "",
    signalName: "",
  });

  const onDeviceDataUpdate = (data: any) => {
    setDeviceData(data);
  };

  const onTrendShowPlot = (data: boolean) => {
    setShowPlot(data);
  };

  const handlePreferenceDialogBox = (data: boolean) => {
    setShowDialogBox(data);
  };

  const onInputCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement> | null,
    deviceId: string,
    deviceName: string,
    signalId: string,
    signalName: string
  ) => {
    if (event && event.target.checked) {
      console.log("inside if condition");
      setCheckedDeviceDetails((prevState) => ({
        ...prevState,
        deviceId: deviceId,
        deviceName: deviceName,
        signalId: signalId,
        signalName: signalName,
      }));
      handlePreferenceDialogBox(true);
    }
    const checkDeviceIdSignalId: boolean = selectedSignalsCheckbox.includes(`${deviceId}-${signalName}`);

    if (checkDeviceIdSignalId) {
      const updatedSelectedSignals = selectedSignalsCheckbox.filter((ele: any) => ele !== `${deviceId}-${signalName}`);
      setSelectedSignalsCheckbox(updatedSelectedSignals);
    } else {
      setSelectedSignalsCheckbox((prevState: any) => [...prevState, `${deviceId}-${signalName}`]);
    }
  };

  const removeDeviceSignal = (deviceID: string, signalName: string) => {
    setSelectedSignalsCheckbox((current: any) => {
      return current.filter((signal: any) => signal !== `${deviceID}-${signalName}`);
    });
  };

  useEffect(() => {
    // storing input name
    localStorage.setItem("selectedSignalsCheckboxSavedData", JSON.stringify(selectedSignalsCheckbox));
  }, [selectedSignalsCheckbox]);

  const value = {
    allDeviceDataCtx: deviceData,
    updateDeviceData: onDeviceDataUpdate,
    onTrendShowPlot: onTrendShowPlot,
    showTrendPlot: showPlot,
    showPreferenceDialogBox: showDialogBox,
    handlePreferenceDialogBox: handlePreferenceDialogBox,
    checkBoxSelectedSignals: selectedSignalsCheckbox,
    onInputCheckboxChange: onInputCheckboxChange,
    checkBoxCheckedData: checkedDeviceDetails,
    removeDeviceSignal: removeDeviceSignal,
  };

  return <DeviceDataContext.Provider value={value}>{children}</DeviceDataContext.Provider>;
};

export default DeviceDataProvider;
