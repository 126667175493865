import styles from "./dashboard.module.scss";
import { FC, useContext, Fragment } from "react";
import Widget from "./widget/Widget";
import AddWidgetPanel from "../add-widget-panel/add-widget-panel";
import { DashboardDataContext } from "../../context/DashboardDataProvider";
import Button from "@mui/material/Button";
import WidgetPreference from "../widget-preference/widget-preference";
import HealthStat from "../widget/HealthStat";
import Footer from "./widget/Footer";
import { useDeviceStore, useUserProfileStore } from "../../common/store/app.store";

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = (props) => {
  const dashboardDataContext = useContext(DashboardDataContext);
  const widgets = useUserProfileStore((state: any) => state.widgets);
  const deviceList = useDeviceStore((state: any) => state.deviceList);

  const onAddWidget = () => {
    dashboardDataContext.toggleAddWidgetPanel();
  };
  console.log("[widgets]", widgets);
  return (
    <div className={styles.main_container}>
      <div className={styles.wrapper}>
        <div className={styles.btn}>
          <Button variant="contained" onClick={onAddWidget} className={styles.add_button}>
            Add widget
          </Button>
        </div>
        <div className={styles.Dashboard}>
          <div className={styles.widget}>
            {Object.keys(widgets).map((deviceId: any) => {
              if (
                widgets[deviceId].filter(
                  (widget: any) =>
                    widget.widgetType === "liveValue" ||
                    widget.widgetType === "healthIndicator" ||
                    widget.widgetType === "healthStats"
                ).length === 0
              ) {
                return <></>;
              } else {
                return (
                  <Fragment key={deviceId}>
                    <div className={styles.decice_name}> {deviceList.getDeviceById(deviceId).name}</div>
                    <div className={styles.device_container}>
                      <div className={styles.device_widgets}>
                        {widgets[deviceId]
                          .filter(
                            (widget: any) =>
                              widget.widgetType === "liveValue" || widget.widgetType === "healthIndicator"
                          )
                          .map((widget: any) => {
                            const widgetId = `${widget.deviceId}-${widget.readingType}-${widget.widgetType}`;
                            return <Widget widgetId={widgetId} widgetProps={widget} key={widgetId} />;
                          })}
                      </div>
                      <div className={styles.device_health_stat}>
                        {widgets[deviceId]
                          .filter((widget: any) => widget.widgetType === "healthStats")
                          .map((widget: any) => {
                            const widgetId = `${widget.deviceId}-${widget.readingType}-${widget.widgetType}`;
                            return <HealthStat widgetId={widgetId} widgetProps={widget} key={widgetId} />;
                          })}
                      </div>
                    </div>
                  </Fragment>
                );
              }
            })}
          </div>
        </div>
      </div>
      {dashboardDataContext.addWidgetPanel && (
        <div className={styles.side_panel}>
          <AddWidgetPanel />
        </div>
      )}
      {dashboardDataContext.showWidgetPreferenceDialog && <WidgetPreference></WidgetPreference>}
      <div className={styles.Footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
