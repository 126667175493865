import React, { useEffect, useState } from 'react';
import { Stack, Box, IconButton, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './slideshow.module.scss';

interface SlideShowProps {
  images: string[];
}

const SlideShowContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  '&:hover $slideshow-buttons': {
    visibility: 'visible',
  },
});

const SlideImage = styled('img')({
    composes: 'slideshow-image',
    width: '100%',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  });
  

const SlideshowButtonsContainer = styled('div')({
  position: 'absolute',
  bottom: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  visibility: 'hidden',

  '& button': {
    margin: '0 16px',
  },
});

const SlideShow: React.FC<SlideShowProps> = ({ images }) => {
  const [index, setIndex] = useState(0);

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SlideShowContainer className="slideshow-container">
      <Fade in={true} key={index} timeout={2000}>
        <Stack spacing={2}>
          <SlideImage src={images[index]} alt={`Slide ${index}`} />
        </Stack>
      </Fade>
      <SlideshowButtonsContainer className="slideshow-buttons">
        <IconButton onClick={handlePrev}>
          <KeyboardArrowUpIcon />
        </IconButton>
        <IconButton onClick={handleNext}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </SlideshowButtonsContainer>
    </SlideShowContainer>
  );
};

export default SlideShow;
