import React, { FC, useEffect, useState, PropsWithChildren, useContext, useRef } from "react";
import styles from "./create-alert.module.scss";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TextField,
  InputAdornment,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  FormHelperText,
  Checkbox,
  Alert,
  Snackbar,
  FormGroup,
  FormControlLabel,
  Input,
} from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axiosClient from "../../common/apis/axiosClient";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { editRow } from "../alerts/menuDots";
import { useNavigate, useLocation } from "react-router";
import { AppContext } from "../../context/AppContext";
import { IDevice, ISignal } from "../../common/modal/device.modal";

var Plotly = require("plotly.js/dist/plotly.js");

interface CreateAlertProps extends PropsWithChildren { }

const actionItems = [
  {
    id: 1,
    name: "Email",
  },
  {
    id: 2,
    name: "Custom Action",
  },
];

const steps = [
  {
    label: "Select Signals",
  },
  {
    label: "Set alert conditions",
  },
  {
    label: "Actions",
  },
];

let yRange: any = null;
let plotPromise: any

const CreateAlert: FC<CreateAlertProps> = (props) => {
  const location = useLocation();

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255)",
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const [threshold, setThreshold] = React.useState<any>("");
  const [thresholdType, setThresholdType] = React.useState<any>("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [deviceData, setDeviceData] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<any>("");
  const [selectedSignalId, setSelectedSignalId] = useState<any>("");
  const [emailSubject, setEmailSubject] = React.useState<any>(`{{readingType}} has exceeded the limit of {{value}}`);
  const [deviceError, setDeviceError] = React.useState<any>();
  const [signalError, setSignalError] = React.useState<any>();
  const [thresholdTypeError, setThresholdTypeError] = React.useState<any>();
  const [unit, setUnit] = React.useState<any>();
  const [entetedThresholdInputIsInvalid, setEntetedThresholdInputIsInvalid] = useState<any>(false);
  const [selectedActions, setSelectedActions] = useState<any>([actionItems[0].id]);
  const [isCustomActionsAvailable, setIsCustomActionsAvailable] = useState<any>(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [shapes, setShapes] = useState<any>([]);
  const [customActions, setCustomActions] = useState<any>({});
  const [allMotorValuesValid, setAllMotorValuesValid] = useState(false);
  const [editorData, setEditorData] = useState<any>();

  const appContext = useContext(AppContext);

  const emailBody = useRef(
    location.state !== null
      ? editorData
      : `<p>Hello {{user}},</p>
            <p><b>ALERT! {{readingType}} has exceeded the limit of {{value}}</b></p>
            <p>Time: {{createdAt}}</p>
            <p>Device: {{device}}</p>
            <p>Signal: {{readingType}}</p>\n
            <p>This message is from <u><a href="https://app.vandyam.com" style="color: blue">https://app.vandyam.com</a></u></p>
            <p>Monitor Anything, Anywhere!</p>`
  );

  useEffect(() => {
    Plotly.newPlot(
      "create_alert_plot",
      [],
      {
        height: 400,
        autosize: true,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          linewidth: 2,
          tickfont: {
            color: "#00000061",
            size: 13,
          },
        },
        yaxis: {
          linewidth: 2,
          overlaying: "y",
          showgrid: false,
          tickfont: {
            color: "#00000061",
            size: 13,
          },
        },
        annotations: [
          {
            text: "Select Device and Signal<br>to create alert",
            xref: "paper",
            yref: "paper",
            showarrow: false,
            font: {
              size: 18,
            },
          },
        ],
      },
      plotConfigs
    );

    editAPI();
  }, []);

  useEffect(() => {
    if (selectedSignalId) {
      const signalType = appContext.deviceList.getDeviceById(selectedDeviceId).getSignalById(selectedSignalId).type;
      getPlotData(selectedDeviceId, signalType);
    }
  }, [shapes]);


  useEffect(() => {
    if (selectedDeviceId) {
      setCustomActions(getAllControls(selectedDeviceId));
      setSelectedActions([actionItems[0].id])

      if (appContext.deviceList.getDeviceById(selectedDeviceId)?.controls.length > 0) {
        setIsCustomActionsAvailable(true);
      }
      else {
        setIsCustomActionsAvailable(false);
      }
    }
  }, [selectedDeviceId]);

  const handleChange = (event: { target: { value: any } }) => {
    const newValue = event.target.value;
    // Prevent the user from deselecting the default option
    if (newValue.length === 0 || (newValue.length === 1 && newValue[0] === actionItems[0].id)) {
      setSelectedActions([actionItems[0].id]);
      setShowSnackbar(true);
      handleSnackbar();
      return;
    }
    setSelectedActions(newValue);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleSnackbar = () => {
    if (selectedActions.length === 0) {
      setSelectedActions([actionItems[actionItems.length - 1].id]);
      setShowSnackbar(true);
    } else if (selectedActions.length === 1 && selectedActions[0] === actionItems[actionItems.length - 1].id) {
      setSelectedActions([actionItems[actionItems.length - 1].id]);
      setShowSnackbar(true);
    } else if (actionItems[0].id) {
      setShowSnackbar(true);
    } else {
      setShowSnackbar(false);
    }
  };

  const getDeviceData = async () => {
    try {
      setDeviceData(appContext.appdata);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleThreshold = async (event: any) => {
    setThreshold(event.target.value);
    setEntetedThresholdInputIsInvalid(null);
    setShapes([]);
    setThresholdType("")
  };

  const handleDeviceChange = (event: any) => {
    setSelectedDeviceId(event.target.value);
    setThreshold("")
    setThresholdType("")
    setShapes([]);
    setDeviceError(null);
  };

  const handleSignalChange = (event: any) => {
    const signalId: string = event.target.value;
    setSelectedSignalId(signalId);
    setThreshold("")
    setThresholdType("")
    setShapes([]);
    setSignalError(null);
    const signalType = appContext.deviceList.getDeviceById(selectedDeviceId).getSignalById(signalId).type;
    getPlotData(selectedDeviceId, signalType);
  };

  const getPlotData = async (deviceID: string, readingType: string) => {
    let plotData: any;

    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };
    const response = await axiosClient.get(`/api/deviceData/${deviceID}?readingType=${readingType}&limit=1000`, config);
    console.log("Create Alert", response);
    if (response.data.device.length > 0) {
      setUnit(response.data.device[0].readingUnit);
    }
    let data = response.data.device
      .sort(function (a: any, b: any) {
        return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
      })
      .reduce(
        (acc: any, data: any) => {
          acc.x.push(data.createdAt);
          acc.y.push(data.reading);
          acc.unit = data.readingUnit;
          return acc;
        },
        { x: [], y: [], unit: null }
      );
    plotData = {
      ...data,
      type: "linear",
      line: { shape: "spline" },
      hovertemplate: "<b>" + `${readingType}` + "</b>: %{y:.2f}" + "<br><br><b>Time</b>: %{x} " + "<extra></extra>",
      marker: {
        color: "blue",
      },
      connectgaps: true,
    };

    let title = "";
    if (readingType && response.data.device.length > 0 && response.data.device[0].readingUnit) {
      title = readingType + ` (${response.data.device[0].readingUnit})`;
    } else if (readingType === 'Water pH') {
      title = readingType;
    }

    const layout = {
      height: 400,
      autosize: true,
      uirevision: "true",
      modebar: {
        position: "center",
      },
      xaxis: {
        title: "Timeline",
        name: "Time",
        linewidth: 2,
        tickformat: "%I:%M %p",
        marker: "red",
        titlefont: {
          family: "Inter",
          size: 14,
          color: "black",
        },
        tickfont: {
          color: "#00000061",
          size: 14,
        },
      },
      yaxis: {
        linewidth: 2,
        title: title,
        marker: "red",
        anchor: "x",
        showgrid: false,
        overlaying: "y",
        titlefont: {
          family: "Inter",
          size: 14,
          color: "black",
        },
        tickfont: {
          color: "#00000061",
          size: 13,
        },
      },
      shapes: shapes,
    };

    plotPromise = Plotly.react("create_alert_plot", [plotData], layout, plotConfig);

    plotPromise.then((plot: any) => {
      yRange = plot.layout.yaxis.range;
      console.log("yRange:", yRange);
    });

  };

  const handleThresholdType = async (event: any) => {
    setThresholdType(event.target.value);
    setThresholdTypeError(null);

    let newShapes: any = [];
    let y0, edit, y1, y;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = (urlSearchParams.get('id'))
    edit = await editRow(location.state);

    if (event.target.value === "above") {
      if (location.state) {
        console.log("using edit.threshold above");
        console.log('id:', id);
        console.log('threshold:', threshold);
        console.log('edit.threshold:', edit.threshold);
        y0 = (Math.max(yRange[0], edit.threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      } else if (location.state && id !== null && edit.threshold !== threshold) {
        console.log("using above");
        y0 = (Math.max(yRange[0], threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      } else {
        console.log("above");
        y0 = (Math.max(yRange[0], threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      }
      newShapes.push({
        layer: "below",
        xref: "paper",
        yref: "paper",
        x0: 0,
        x1: 1,
        y0,
        y1: 1,
        type: "rect",
        fillcolor: "#fec8c8",
        opacity: 1,
        line: {
          width: 0,
        },
      });
    }
    if (event.target.value === "below") {
      if (location.state) {
        console.log("using edit.threshold below");
        console.log('id:', id);
        console.log('threshold:', threshold);
        console.log('edit.threshold:', edit.threshold);
        y1 = (Math.min(yRange[1], edit.threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      } else if (location.state && id !== null && edit.threshold !== threshold) {
        console.log("using below");
        y1 = (Math.max(yRange[0], threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      } else {
        console.log("below");
        y1 = (Math.max(yRange[0], threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      }
      newShapes.push({
        layer: "below",
        xref: "paper",
        yref: "paper",
        x0: 0,
        x1: 1,
        y0: 0,
        y1,
        type: "rect",
        fillcolor: "#fec8c8",
        opacity: 1,
        line: {
          width: 0,
        },
      });
    }

    if (event.target.value === "equal") {
      if (location.state) {
        console.log("using edit.threshold equal");
        console.log('id:', id);
        console.log('threshold:', threshold);
        console.log('edit.threshold:', edit.threshold);
        y = (Math.min(yRange[1], Math.max(yRange[0], edit.threshold)) - yRange[0]) / (yRange[1] - yRange[0]);
      } else if (location.state && id !== null && edit.threshold !== threshold) {
        console.log("using equal");
        y = (Math.max(yRange[0], threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      } else {
        console.log("equal");
        y = (Math.max(yRange[0], threshold) - yRange[0]) / (yRange[1] - yRange[0]);
      }
      newShapes.push({
        type: "line",
        xref: "paper",
        yref: "paper",
        x0: 0,
        y0: y,
        x1: 1,
        y1: y,
        line: {
          color: "rgb(255, 204, 203)",
          width: 3,
        },
      });
    }
    setShapes(newShapes);
  };

  const handleEditorChange = (event: any, editor: { getData: () => any }) => {
    const data = editor.getData();
    emailBody.current = data;
  };

  const thresholdInputBlurHandler = () => {
    if (threshold === "") {
      setEntetedThresholdInputIsInvalid(true);
    } else {
      setEntetedThresholdInputIsInvalid(false);
    }
  };

  const handleNext = () => {
    let isError = false;

    if (!selectedDeviceId) {
      isError = true;
      setDeviceError(!selectedDeviceId);
      if (selectedDeviceId) {
        isError = false;
        setDeviceError(null);
      }
    } else if (!selectedSignalId) {
      isError = true;
      setSignalError(!selectedSignalId);
      if (selectedSignalId) {
        isError = false;
        setSignalError(null);
      }
    } else if (threshold === "") {
      isError = true;
      setEntetedThresholdInputIsInvalid(true);
      if (!threshold) {
        isError = false;
        setEntetedThresholdInputIsInvalid(false);
      }
    } else if (!thresholdType) {
      isError = true;
      setThresholdTypeError(!thresholdType);
      if (thresholdType) {
        isError = false;
        setThresholdTypeError(null);
      }
    }

    if (!isError) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setDeviceError(null);
    setSignalError(null);
    setThresholdTypeError(null);
  };

  const navigate = useNavigate();

  const navigateToAlerts = () => {
    navigate("/alerts");
  };

  const handleSave = async () => {
    if (location.state !== null) {
      editAlert(location.state);
      setTimeout(() => {
        navigate("/alerts");
      }, 1000);
    } else {
      await postAlertConditions();
      setTimeout(() => {
        navigate("/alerts");
      }, 1000);
    }
  };

  var plotConfig = {
    responsive: true,
    scrollZoom: true,
    displayModeBar: true,
    displaylogo: false,
  };

  var plotConfigs = {
    responsive: true,
    displayModeBar: false,
  };

  const postAlertConditions = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };

    let actionsToSave = [];

    for (const key in customActions) {
      if (customActions[key].enable)
        actionsToSave.push({
          id: customActions[key].name,
          dosage: customActions[key].dosage,
          state: customActions[key].state,
          unit: customActions[key].unit,
          min: customActions[key].min,
          max: customActions[key].max,
        });
    }
    axiosClient
      .post(
        `api/alert`,
        {
          device: selectedDeviceId,
          readingType: appContext.deviceList.getDeviceById(selectedDeviceId).getSignalById(selectedSignalId).type,
          status: "Active",
          threshold: threshold,
          thresholdType: thresholdType,
          emailSubject: emailSubject,
          emailBody: emailBody.current,
          controlsTrigger: actionsToSave,
        },
        config
      )
      .then((response: { data: any; status: number }) => {
        if (response.status === 200) {
          console.log("saved alert", response);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const editAPI = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = (urlSearchParams.get('id'))

    if (location.state) {
      let edit = await editRow(location.state);
      setSelectedDeviceId(edit.deviceID);
      setSelectedSignalId(appContext.deviceList.getDeviceById(edit.deviceID)?.getSignalByName(edit.signal).id || '');
      setThreshold(edit.threshold);
      setThresholdType(edit.thresholdType);
      setEmailSubject(edit.emailSubject);
      setEditorData(edit.emailBody);
      await getPlotData(edit.deviceID, edit.signal);
      handleThresholdType({ target: { value: edit.thresholdType } });
      let controls = getAllControls(edit.deviceID);
      let editControlsMap = edit.controls.reduce((acc: any, control: any) => {
        acc[control.id] = control;
        return acc;
      }, {});
      for (const key in controls) {
        if (editControlsMap[key]) {
          controls[key] = {
            ...controls[key],
            enable: true,
            name: editControlsMap[key].id,
            state: editControlsMap[key].state,
            dosage: editControlsMap[key].dosage || null,
          };
        }
      }
      setTimeout(() => {
        setCustomActions(controls);
      });
    } else if (id !== null) {
      navigate('/dashboard')
    }
  }

  const getAllControls = (_selectedDeviceId: string) => {
    return appContext.deviceList.getDeviceById(_selectedDeviceId)?.controls.reduce((acc: any, action: any) => {
      acc[action.name] = {
        enable: false,
        state: true,
        dosage: null,
        name: action.name,
        unit: action.unit,
        min: action.min,
        max: action.max,
      };
      return acc;
    }, {});
  };

  const renderValue = (selected: any[]) => {
    return selected
      .map((value: number) => {
        const item = actionItems.find((item) => item.id === value);
        return item ? item.name : null;
      })
      .join(", ");
  };

  const editAlert = async (editID: any) => {
    let deviceID: any = selectedDeviceId;
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `${token}` },
      };

      let actionsToSave = [];

      for (const key in customActions) {
        if (customActions[key].enable) {
          const dosage = customActions[key].dosage;
          if (dosage === "") {
            let isValid = false;
          }
          if (customActions[key].enable)
            actionsToSave.push({
              id: customActions[key].name,
              dosage: customActions[key].dosage,
              state: customActions[key].state,
              unit: customActions[key].unit,
              min: customActions[key].min,
              max: customActions[key].max,
            });
        }
      }


      if (editID) {
        const response = await axiosClient.put(
          `/api/alert/${editID}`,
          {
            device: selectedDeviceId,
            readingType: appContext.deviceList.getDeviceById(selectedDeviceId).getSignalById(selectedSignalId).type,
            status: "Active",
            threshold: threshold,
            thresholdType: thresholdType,
            emailSubject: emailSubject,
            emailBody: emailBody.current,
            controlsTrigger: actionsToSave,
          },
          config
        );
        console.log("response from editAlert", response);
      }
    } catch (error) {
      console.log("Edit Alert Error", error);
    }
  };

  const handleRemove = (idToRemove: any) => {
    setSelectedActions((prevActions: any[]) => {
      const lastIndex = prevActions.length - 1;
      if (prevActions[lastIndex] === idToRemove && prevActions.length === 1) {
        setShowSnackbar(true);
        handleSnackbar();
        return prevActions;
      }
      return prevActions.filter((id: any) => id !== idToRemove);
    });
  };

  const renderMenuItem = (action: any, checked: boolean, disabled: boolean) => {
    return (
      <MenuItem key={action.id} value={action.id}>
        <Checkbox checked={checked} disabled={disabled} />
        <span>{action.name}</span>
      </MenuItem>
    );
  };

  const ActionComponent = ({
    actionId,
    component,
    onRemove,
  }: {
    actionId: any;
    component: any;
    onRemove: (id: any) => void;
  }) => {
    const actionItem = actionItems.find((item) => item.id === actionId);

    return (
      <>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1, mt: 1, maxWidth: 520 }}>
          <div>{actionItem?.name}</div>
          <div style={{ marginLeft: "auto" }}>
            <Button variant="text" onClick={() => onRemove(actionId)} color="error">
              Remove Action
            </Button>
          </div>
        </Box>
        {component}
      </>
    );
  };

  const Email = () => {
    return (
      <div>
        <Box
          padding={"7px"}
          paddingTop={"10px"}
          sx={{
            width: 500,
            maxWidth: "100%",
            border: 1,
            borderRadius: "5px",
            borderColor: "grey.500",
            "@media (max-width: 800px)": {
              width: "100%", // occupy full width on mobile screens
            },
          }}
        >
          <TextField
            variant="standard"
            fullWidth
            placeholder="Subject"
            id="fullWidth"
            onFocus={(event) => {
              event.preventDefault(); // Prevent the default behavior of the browser
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
            autoFocus={isFocused}
            onChange={(event) => setEmailSubject(event.target.value)}
            value={emailSubject}
          />
          <CKEditor
            editor={ClassicEditor}
            onChange={handleEditorChange}
            data={emailBody.current || editorData}
            onBlur={(editor: any) => {
              console.log("Blur.", editor);
            }}
            onFocus={(editor: any) => {
              console.log("Focus.", editor);
            }}
          />
        </Box>
      </div>
    );
  };

  const SMS = () => {
    return (
      <div>
        <Box
          padding={"7px"}
          paddingTop={"10px"}
          sx={{
            width: 500,
            maxWidth: "100%",
            border: 1,
            borderRadius: "5px",
            borderColor: "grey.500",
          }}
        >
          <TextField
            id="outlined-multiline-static"
            fullWidth
            multiline
            rows={4}
            defaultValue="Default Value"
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
      </div>
    );
  };

  const isSaveDisabled =
    !selectedActions.length ||
    (selectedActions.length === 1 && selectedActions[0] === 1)

  const CustomAction = () => {
    const handleDosageChangeFn = (action: any) => {
      return (event: any) => {
        setCustomActions((current: any) => {
          let object = { ...current };
          object[action].dosage = event.target.value;
          return object;
        });
        console.log("action", action, event.target.value);

        // Check if all motor values are valid
        const allMotorValuesValid = Object.values(customActions).every(
          (action: any) => {
            if (action.enable && !action.dosage) {
              return false;
            } else if (!action.enable) {
              return true;
            }
            return true;
          }
        );
        setAllMotorValuesValid(allMotorValuesValid);
      };
    };


    const handleCheckboxChangeFn = (action: any) => {
      return (event: any) => {
        setCustomActions((current: any) => {
          let object = { ...current };
          object[event.target.value].enable = !object[event.target.value].enable;
          return object;
        });

        // Update selected actions state
        setSelectedActions((current: any) => {
          let actions = [...current];
          if (event.target.checked && !actions.includes(event.target.value)) {
            actions.push(event.target.value);
          } else if (!event.target.checked && actions.includes(event.target.value)) {
            actions.splice(actions.indexOf(event.target.value), 1);
          }
          return actions;
        });

        // Check if at least one motor value is selected
        const atLeastOneMotorSelected = Object.values(customActions).some(
          (action: any) => {
            return action.enable;
          }
        );
        setAllMotorValuesValid(atLeastOneMotorSelected);

        console.log("action", action, event.target.value);
      };
    };


    const handleStateChangeFn = (action: any) => {
      return (event: any) => {
        setCustomActions((current: any) => {
          let object = { ...current };
          object[action].state = event.target.value;
          return object;
        });
        console.log("action", action, event.target.value);
      };
    };
    return (
      <div>
        <Box
          padding={"7px"}
          paddingTop={"5px"}
          display={"flex"}
          alignItems={"normal"}
          flexDirection={"column"}
          gap={"1rem"}
          sx={{
            width: 600,
            maxWidth: "100%",
            border: 1,
            borderRadius: "5px",
            borderColor: "grey.500",
          }}
        >
          {
            <FormGroup>
              {appContext.deviceList.getDeviceById(selectedDeviceId).controls.map((control: any) => {
                switch (control.type) {
                  case "motor":
                    return (
                      <div className={styles.motor_action} key={control.name}>
                        <FormControlLabel
                          sx={{ marginRight: "0" }}
                          value={control.name}
                          control={
                            <Checkbox
                              onChange={handleCheckboxChangeFn(control.name)}
                              checked={customActions[control.name].enable}
                            />
                          }
                          label={""}
                        />
                        <FormControl variant="standard">
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={customActions[control.name].state}
                            onChange={handleStateChangeFn(control.name)}
                          >
                            <MenuItem value={true as any}>Turn on</MenuItem>
                            <MenuItem value={false as any}>Turn off</MenuItem>
                          </Select>
                        </FormControl>
                        <div className={styles.action_text}>
                          {control.name} {customActions[control.name].state && <span> and dispense </span>}
                        </div>
                        {customActions[control.name].state && (
                          <FormControl variant="standard">
                            <Input
                              id="standard-adornment-weight"
                              defaultValue={customActions[control.name].dosage}
                              endAdornment={
                                <InputAdornment position="end">{customActions[control.name].unit}</InputAdornment>
                              }
                              aria-describedby="standard-weight-helper-text"
                              inputProps={{
                                "aria-label": "dosage",
                              }}
                              placeholder="Dosage"
                              onBlur={handleDosageChangeFn(control.name)}
                            />
                            {customActions[control.name].enable && customActions[control.name].dosage === "" && (
                              <Typography color="error" variant="caption">Please enter a value for dosage</Typography>
                            )}
                          </FormControl>
                        )}
                      </div>
                    );
                  case "FOGGER":
                    return (
                      <div className={styles.fogger_action} key={control.name}>
                        <FormControlLabel
                          sx={{ marginRight: "0" }}
                          value={control.name}
                          control={
                            <Checkbox
                              onChange={handleCheckboxChangeFn(control.name)}
                              checked={customActions[control.name].enable}
                            />
                          }
                          label={""}
                        />
                        <div className={styles.action_text}>{control.name}</div>
                        <FormControl variant="standard">
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={customActions[control.name].state}
                            onChange={handleStateChangeFn(control.name)}
                          >
                            <MenuItem value={true as any}>Turn on</MenuItem>
                            <MenuItem value={false as any}>Turn off</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    );
                  case "PUMP":
                    return (
                      <div className={styles.pump_action} key={control.name}>
                        <FormControlLabel
                          sx={{ marginRight: "0" }}
                          value={control.name}
                          control={
                            <Checkbox
                              onChange={handleCheckboxChangeFn(control.name)}
                              checked={customActions[control.name].enable}
                            />
                          }
                          label={""}
                        />
                        <div className={styles.action_text}>{control.name}</div>
                        <FormControl variant="standard">
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={customActions[control.name].state}
                            onChange={handleStateChangeFn(control.name)}
                          >
                            <MenuItem value={true as any}>Turn on</MenuItem>
                            <MenuItem value={false as any}>Turn off</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    );
                  default:
                    return null;
                }
              })}
            </FormGroup>
          }
        </Box>
      </div>
    );
  };


  const Buzzer = () => {
    return (
      <div>
        <Box
          padding={"7px"}
          paddingTop={"10px"}
          sx={{
            width: 500,
            maxWidth: "100%",
            border: 1,
            borderRadius: "5px",
            borderColor: "grey.500",
          }}
        >
          Buzzer
        </Box>
      </div>
    );
  };

  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.backButton}>
          <LightTooltip title="Back" placement="right">
            <IconButton onClick={navigateToAlerts}>
              <ArrowBackTwoToneIcon fontSize={"large"} style={{ color: "blue" }} />
            </IconButton>
          </LightTooltip>
        </div>

        <div className={styles.wrapperCard}>
          {/* PLOT CONTAINER */}
          <div className={styles.PlotComponent} id="create_alert_plot"></div>

          {/* STEPPER */}
          <div className={styles.stepper}>
            <Box sx={{ marginLeft: "4.5rem", paddingTop: "2rem", maxWidth: 850 }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step
                    key={index}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "#32CD32", // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                        color: "black", // Just text label (COMPLETED)
                      },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "primary", // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                        color: "common.white", // Just text label (ACTIVE)
                      },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel>{step.label}</StepLabel>

                    <StepContent>
                      {step.label === "Select Signals" && (
                        <div>
                          <FormControl
                            variant="standard"
                            sx={{
                              m: 1,
                              minWidth: 180,
                              borderRadius: "2px",
                              paddingRight: "15px",
                              paddingBottom: "2rem",
                            }}
                          >
                            <InputLabel>Device</InputLabel>
                            <Select onChange={handleDeviceChange} value={selectedDeviceId} required>
                              {appContext.deviceList.getAllDevices().map((device: any) => {
                                return (
                                  <MenuItem value={device.id} key={device.name}>
                                    {device.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {deviceError && <FormHelperText error>Device not selected!</FormHelperText>}
                          </FormControl>

                          <FormControl
                            variant="standard"
                            sx={{
                              m: 1,
                              minWidth: 180,
                              borderRadius: "2px",
                              paddingBottom: "2rem",
                            }}
                          >
                            <InputLabel>Signal</InputLabel>
                            <Select
                              value={selectedSignalId}
                              onChange={(e) => {
                                handleSignalChange(e);
                              }}
                              required
                              MenuProps={{
                                anchorOrigin: { vertical: "bottom", horizontal: "center" },
                              }}
                            >
                              {appContext.deviceList.getSignalsByDeviceId(selectedDeviceId).map((signal: ISignal) => {
                                return (
                                  <MenuItem value={signal.id} key={signal.id}>
                                    {signal.type}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {signalError && <FormHelperText error>Signal not selected!</FormHelperText>}
                          </FormControl>
                        </div>
                      )}

                      {step.label === "Set alert conditions" && (
                        <div>
                          <Box display={"flex"} flexDirection={"row"} paddingBottom={"2rem"}>
                            <Typography marginTop={"1.5rem"}>Alert Threshold: &nbsp;</Typography>
                            <TextField
                              value={threshold}
                              onChange={handleThreshold}
                              onBlur={thresholdInputBlurHandler}
                              id="standard-start-adornment"
                              sx={{ marginTop: 1.5, width: 35 }}
                              type="number"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                                sx: { width: "7rem" },
                              }}
                              variant="standard"
                              error={entetedThresholdInputIsInvalid}
                              helperText={
                                entetedThresholdInputIsInvalid && (
                                  <span dangerouslySetInnerHTML={{ __html: "Enter&nbsp;threshold!" }} />
                                )
                              }
                              autoFocus
                            />
                          </Box>
                          <Box display={"flex"} flexDirection={"row"} paddingBottom={"1rem"}>
                            <Typography marginTop={"1rem"}>Trigger when the value is &nbsp;</Typography>
                            <FormControl
                              variant="standard"
                              sx={{
                                m: 1,
                                minWidth: 120,
                                borderRadius: "2px",
                              }}
                            >
                              <Select
                                value={thresholdType}
                                onChange={handleThresholdType}
                                defaultValue={""}
                                MenuProps={{
                                  anchorOrigin: { vertical: "bottom", horizontal: "center" },
                                }}
                              >
                                <MenuItem value={"above"}>Above</MenuItem>
                                <MenuItem value={"below"}>Below</MenuItem>
                                <MenuItem value={"equal"}>Equal</MenuItem>
                              </Select>
                              {thresholdTypeError && (
                                <FormHelperText error>Threshold Type not selected!</FormHelperText>
                              )}
                            </FormControl>
                            <Typography marginTop={"1rem"}>&nbsp;the threshold.</Typography>
                          </Box>
                        </div>
                      )}

                      {step.label === "Actions" && (
                        <div>
                          <FormControl
                            variant="standard"
                            sx={{
                              m: 1,
                              minWidth: 150,
                              borderRadius: "2px",
                              position: "relative",
                            }}
                          >
                            <InputLabel>Select a Action</InputLabel>
                            <Select
                              multiple
                              value={selectedActions}
                              onChange={handleChange}
                              renderValue={renderValue}
                              MenuProps={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" },
                                PaperProps: { style: { position: "absolute" } },
                              }}
                            >
                              {isCustomActionsAvailable
                                ? actionItems.map((action: any) => {
                                  const checked = selectedActions.indexOf(action.id) > -1;
                                  const disabled = action.id === actionItems[0].id && selectedActions.length === 1;
                                  return renderMenuItem(action, checked, disabled);
                                })
                                : renderMenuItem(
                                  actionItems[0],
                                  selectedActions.indexOf(actionItems[0].id) > -1,
                                  selectedActions.length === 1
                                )}
                            </Select>
                            <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                              <Alert severity="error" sx={{ width: "100%" }}>
                                At least one option should be selected at all times.
                              </Alert>
                            </Snackbar>
                          </FormControl>
                          {selectedActions.map((actionId: React.Key | null | undefined) => {
                            switch (actionId) {
                              case 1:
                                return (
                                  <ActionComponent
                                    key={actionId}
                                    actionId={actionId}
                                    component={<Email />}
                                    onRemove={handleRemove}
                                  />
                                );
                              case 2:
                                return (
                                  <ActionComponent
                                    key={actionId}
                                    actionId={actionId}
                                    component={<CustomAction />}
                                    onRemove={handleRemove}
                                  />
                                );
                              case 3:
                                return (
                                  <ActionComponent
                                    key={actionId}
                                    actionId={actionId}
                                    component={<Buzzer />}
                                    onRemove={handleRemove}
                                  />
                                );
                              case 4:
                                return (
                                  <ActionComponent
                                    key={actionId}
                                    actionId={actionId}
                                    component={<SMS />}
                                    onRemove={handleRemove}
                                  />
                                );
                              default:
                                return null;
                            }
                          })}
                        </div>
                      )}

                      <Box sx={{ mb: 2 }}>
                        <div>
                          {index === steps.length - 1 ? (
                            <Button
                              variant="contained"
                              disabled={!isSaveDisabled && !allMotorValuesValid}
                              onClick={() => {
                                handleSave();
                              }}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              SAVE
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              title="NEXT"
                              onClick={() => {
                                handleNext();
                              }}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              NEXT
                            </Button>
                          )}
                          <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                            BACK
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAlert;