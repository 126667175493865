import React, { FC, useEffect, useState, PropsWithChildren, Component, useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, styled, TextField, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { AppContext } from "../../context/AppContext";
import axiosClient from "../../common/apis/axiosClient";
import styles from "./add-device.module.scss";
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ClearIcon from '@mui/icons-material/Clear';

interface AddDeviceProps extends PropsWithChildren {
}

const AddDevice: React.FC<AddDeviceProps> = (props) => {

    const appContext = useContext(AppContext);

    const [deviceID, setDeviceID] = useState<any>('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogContext, setDialogContext] = useState<any>('');
    const [signaldropDown, setSignalDropDown] = useState<any>([]);

    const navigate = useNavigate();

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            color: "rgba(255, 255, 255)",
            boxShadow: theme.shadows[1],
            fontSize: 15,
        },
    }));

    const navigateToAlerts = () => {
        navigate("/deviceManagement");
    };

    const handleDeviceField = (e: { target: { value: any; }; }) => {
        setDeviceID(e.target.value)

        if (deviceID) {
            setError(false);
            setHelperText('');
        }
    }

    const handleAddDevice = async () => {
        if (!deviceID) {
            setError(true);
            setHelperText('Please enter a device ID');
            return;
        }

        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `${token}` },
        };
        try {
            const response = await axiosClient.put('/api/userProfile', {
                deviceId: deviceID,
            }, config);
            if (response.status === 200) {
                console.log('Device added successfully', response);
                setDialogMessage("Device Added Successfully");
                setDialogContext(`The available signals are:`)
                setOpenDialog(true);
                showSignals();
            }
        } catch (error) {
            console.error('Error adding device', error);
            setDialogMessage("Error Adding Device");
            setDialogContext('Enter a valid Device ID.')
            setOpenDialog(true);
        }
    };

    const showSignals = async () => {
        const _signalDD: any[] | never[] = []

        appContext.appdata.forEach((device: any) => {
            if (device._id === deviceID) {
                device.parameters.forEach((signal: any) => {
                    // @ts-ignore:next-line                    
                    _signalDD.push(signal.type);
                })
            }
        })
        setSignalDropDown(_signalDD);
    }

    const handleBlur = () => {
        if (!deviceID) {
            setError(true);
            setHelperText('Please enter a device ID');
        }
        else {
            setError(false);
            setHelperText('');
        }
    };

    const handleClearInput = () => {
        setDeviceID('');
        setError(false);
        setHelperText('');
    };

    return (
        <>
            <div className={styles.main_container}>
                <div className={styles.backButton}>
                    <LightTooltip title="Back" placement="right">
                        <IconButton onClick={navigateToAlerts}>
                            <ArrowBackTwoToneIcon fontSize={"large"} style={{ color: "blue" }} />
                        </IconButton>
                    </LightTooltip>
                </div>
                <div className={styles.wrapperCard}>
                    <div className={styles.textFieldBtn}>
                        <TextField
                            variant="standard"
                            placeholder="Enter deviceID"
                            sx={{
                                width: 300
                            }}
                            value={deviceID}
                            onChange={handleDeviceField}
                            onBlur={handleBlur}
                            error={error}
                            helperText={helperText}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="clear input"
                                        onClick={handleClearInput}
                                        edge="end"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                )
                            }}
                        />
                        <Button
                            sx={{ minHeight: 50, fontSize: 15, fontWeight: 'bold', }}
                            variant="contained"
                            className={styles.add_button}
                            onClick={handleAddDevice}
                        >
                            Add Device
                        </Button>

                        <Dialog open={openDialog}>
                            <DialogTitle>{dialogMessage}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {dialogContext}
                                    <ul>
                                        {signaldropDown.map((item: any, index: any) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDialog(false)} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>

    )

}

export default AddDevice;