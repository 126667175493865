import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { Route, Routes, useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import ManageAlert from './manage-alert';
import TriggeredAlert from './triggered-alert'
import styles from "./alerts.module.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const navigateToCreateAlert = () => {
    navigate('/createAlert');
  };


  return (
    <>
      <div className={styles.add_alert_mb}>
        <Button variant="contained" onClick={navigateToCreateAlert}>ADD ALERTS</Button>
      </div>
      <div className={styles.alertsTab}>

        <div className={styles.topalertsTab}>

          <Box sx={{ width: '100%', justifyContent: "center", }}>
            <Tabs sx={{ width: '100%', justifyContent: "center", }} value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Manage Alerts" {...a11yProps(0)} />
              <Tab label="Triggered Alerts" {...a11yProps(1)} />

              <Box sx={{
                width: '100%',
                '@media screen and (max-width: 48em)': {
                  display: 'none'
                },
              }} display="flex"
                justifyContent="flex-end"
              >
                <Button variant="contained" onClick={navigateToCreateAlert}>ADD ALERTS</Button>
              </Box>
            </Tabs>


            <TabPanel value={value} index={0} >
              <ManageAlert />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TriggeredAlert />
            </TabPanel>
          </Box>
        </div>
      </div>
    </>
  );
}
