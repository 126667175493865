import { create } from "zustand";
import { Device, DeviceList } from "../modal/device.modal";

export const useDeviceStore = create<{ deviceList: any; deviceLiveData: any }>((set, get) => ({
  deviceList: new DeviceList(),
  deviceLiveData: {},
  setDevices: (devices: any) => {
    set(() => {
      let deviceList = new DeviceList();
      for (let i = 0; i < devices.length; i++) {
        let deviceRaw = devices[i];
        let device = new Device(deviceRaw.name, deviceRaw.createdAt, deviceRaw._id, deviceRaw.updatedAt);
        device.setControls(deviceRaw.controls);
        device.setSignals(deviceRaw.parameters);
        deviceList.addDevice(device);
      }
      return { deviceList };
    });
  },
  setDeviceLiveData: (data: any) => {
    set(() => {
      const _deviceData = get().deviceLiveData;
      if (!_deviceData[data.device]) _deviceData[data.device] = {};
      _deviceData[data.device][data.readingType] = data;
      return { deviceLiveData: _deviceData };
    });
  },
  getDeviceById(id: string) {
    if (!id) return null;
    console.log("[Device by Id]:", get().deviceList[id]);
    return get().deviceList[id];
  },
  getDeviceData(deviceId: string, signal: string) {
    if (!(deviceId && signal && get().deviceLiveData[deviceId] && get().deviceLiveData[deviceId][signal])) return null;
    console.log("[Device data]:", get());
    return get().deviceLiveData[deviceId][signal];
  },
}));

export const useUserProfileStore = create((set) => ({
  devices: [],
  widgets: {},
  remoteMonitoring: [],
  // setWidgets: (widgets: any) => set(() => ({ devices: useDeviceStore.getState().devices })),
  setWidgets: (widgets: any) => set(() => ({ widgets })),
}));
