import axios, { AxiosInstance, AxiosPromise } from "axios";

const axiosClient:AxiosInstance = axios.create({
  baseURL: "https://app.vandyam.com",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use((config)=>{
  const token = localStorage.getItem("token");
  if(!config.headers){
    config.headers = {};
  }
  config.headers.Authorization = `${token}`;
return config;
}, (error)=>{
  return Promise.reject(error)
})

export const responseResolver = <T extends any[], R>(
  request: (...args: T) => AxiosPromise<R>,
) => {
  type Response = Promise<Awaited<ReturnType<typeof request>>['data']>;

  return async (...args: T): Promise<any> => {
    const response = await request(...args);

    return response.data;
  };
};


export default axiosClient;
