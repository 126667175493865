import * as React from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axiosClient from "../../common/apis/axiosClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AxiosResponse } from "axios";

const notifyMute = () => toast.success("Muted");
const notifyUnMute = () => toast.success("Unmuted");
const notifyDelete = () => toast.error("Deleted");

const muteRow = async (id: string, alertData: any, setAlertData: React.Dispatch<React.SetStateAction<never[]>>) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };
    const muteAlert = await axiosClient.put(
      `api/alert/${id}`,
      {
        status: "Mute",
      },
      config
    );
    console.log("muteAlert", muteAlert);
    if (muteAlert.status === 200) {
      notifyMute();
      const getallAlerts = await axiosClient.get("api/alert", config);
      console.log("getallAlerts", getallAlerts);
      setAlertData(getallAlerts.data.alerts);
    }
  } catch (error) {
    console.log("error", error);
  }
};

const unMuteRow = async (id: string, alertData: any, setAlertData: React.Dispatch<React.SetStateAction<never[]>>) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };
    const muteAlert = await axiosClient.put(
      `api/alert/${id}`,
      {
        status: "Active",
      },
      config
    );
    console.log("unmuteAlert", muteAlert);
    notifyUnMute();
    const getallAlerts = await axiosClient.get("api/alert", config);
    console.log("getallAlerts", getallAlerts);
    setAlertData(getallAlerts.data.alerts);
  } catch (error) {
    console.log("error", error);
  }
};

export const editRow = async (id: any) => {
  let motorName: any,
    deviceID: any,
    device: any,
    signal: any,
    threshold: any,
    thresholdType: any,
    emailSubject: any,
    emailBody: any,
    controls: any;
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };
    const response = await axiosClient.get(`api/alert/${id}`, config);
    if (response.status === 200) {
      deviceID = response.data.alert.device;
      device = response.data.alert.deviceName;
      signal = response.data.alert.readingType;
      threshold = response.data.alert.threshold;
      thresholdType = response.data.alert.thresholdType;
      emailSubject = response.data.alert.emailSubject;
      emailBody = response.data.alert.emailBody;
      controls = response.data.alert.controlsTrigger;
    }
    console.log("editAlert", response);
  } catch (error) {
    console.log("error", error);
  }

  const idParams = new URLSearchParams(window.location.search);
  // idParams.set('id', id);
  if (!idParams.has('id')) {
    idParams.append('id', id);
  }

  // Replace the current URL with the new URL containing the query parameter.
  const newUrl = `${window.location.pathname}?${idParams.toString()}`;
  window.history.replaceState(null, '', newUrl);

  return {
    deviceID,
    device,
    signal,
    threshold,
    thresholdType,
    emailBody,
    emailSubject,
    controls,
  };
};

const deleteRow = async (id: string, alertData: any, setAlertData: React.Dispatch<React.SetStateAction<never[]>>) => {
  console.log(alertData);
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };
    const deleteAlert = await axiosClient.delete(`api/alert/${id}`, config);
    console.log("deleteAlert", deleteAlert);
    if (deleteAlert.status === 200) {
      notifyDelete();
      alertData = alertData.filter((alert: { _id: string }) => !(alert._id === id));
      setAlertData(alertData);
    }
  } catch (error) {
    console.log("error", error);
  }
};

const ITEM_HEIGHT = 48;

export default function LongMenu(prop: {
  id: string;
  selectedAlert: any;
  alertData: any;
  setAlertData: React.Dispatch<React.SetStateAction<never[]>>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const navigateToCreateAlert = (id: string) => {
    navigate("/createAlert", { state: id });
  };

  let options: (any | any)[];
  options = [
    ["Edit", navigateToCreateAlert],
    ["Delete", deleteRow],
    [
      prop.selectedAlert.status === "Mute" ? "Unmute" : "Mute",
      prop.selectedAlert.status === "Mute" ? unMuteRow : muteRow,
    ],
  ];

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      //aria-haspopup="false"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option[0]}
            selected={option[0] === "Pyxis"}
            onClick={() => {
              handleClose();
              option[1](prop.id, prop.alertData, prop.setAlertData);
              //notify()
              // navigateToCreateAlert(prop.id)
            }}
          >
            {option[0]}
          </MenuItem>
        ))}
      </Menu>
      <ToastContainer
        position={"top-center"}
        closeOnClick={false}
        pauseOnHover={false}
        draggable={false}
        autoClose={3000}
      />
    </div>
  );
}
