import React, { FC } from "react";
import { useState, createContext, useContext } from "react";


interface AuthProviderProps{
 children?:React.ReactNode
}
const defaultValue = {login:(a:boolean)=>{},user:false,logout:()=>{}}
const AuthContext = createContext(defaultValue)

export const AuthProvider:FC<AuthProviderProps> = ({children}) => {

    const [user, setUser] = useState(false);

    const login = (user: boolean | ((prevState: boolean) => boolean)) => {
        setUser(user);
    }

    const logout = () => {
        setUser(false);
    }
    
    return(
        <AuthContext.Provider value={{user, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}