import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import MenuDots from './menuDots'
import axiosClient from '../../common/apis/axiosClient';
import styles from "./alerts.module.scss";
import searchBar from "../../shared/assets/icons/searchBar.svg"
import { useMediaQuery } from '@mui/material';
import Footer from '../dashboard/widget/Footer';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (

    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


export default function CustomPaginationActionsTable() {
  const [alertData, setAlertData] = React.useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: { Authorization: `${token}` },
        };
        const getallAlerts = await axiosClient.get("api/alert", config);
        console.log("getallAlerts", getallAlerts);
        setAlertData(getallAlerts.data.alerts);

      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const filterAlerts = (searchKey: any) => {
    setAlertData(alertData.filter((alert: any) => alert.readingType.toLowerCase().indexOf(searchKey.target.value.toLowerCase()) >= 0));
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - alertData.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUnit = (unit: any) => {
    if (unit.indexOf('Temp') >= 0) {
      return 'C';
    } else if (unit.indexOf('Pressure') >= 0) {
      return 'Pa';
    }
    else if (unit.indexOf('Voltage') >= 0) {
      return 'V'
    }
    else if (unit.indexOf('Current') >= 0) {
      return 'A'
    }
    else if (unit.indexOf('TDS') >= 0) {
      return 'ppm'
    }
    else if (unit.indexOf('Water Temperature') >= 0) {
      return 'C'
    };
    return '';
  }

  return (
    <div>

      <div className={styles.searchBar}>
        <img className={styles.searchBarImg} src={searchBar} alt="" />

        <input type="text" onChange={filterAlerts} placeholder="Search alert name" />

      </div>
      <TableContainer component={Paper} >
        <Table sx={{ minwidth: 200 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell key={'alerts'} sx={{ fontWeight: 'bold', fontSize: 16 }}>Alerts</TableCell>
              <TableCell key={'status'} sx={{ fontWeight: 'bold', fontSize: 16,
              '@media screen and (max-width: 48em)': {
                display:'none'
                },  
            }}>Status</TableCell>
              <TableCell key={'threshold'} sx={{ fontWeight: 'bold', fontSize: 16,
            '@media screen and (max-width: 48em)': {
              display:'none'
              },  
            }}>Threshold</TableCell>
            <TableCell key ={'customaction'} sx ={{fontWeight : 'bold',fontSize:16,
            '@media screen and (max-width: 48em)': {
              display:'none'
              },  
            }}>Custom Action</TableCell>

              <TableCell align="left"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? alertData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : alertData
            ).map((alert: any, _id: any) => (
              <TableRow
              key = {alert._id}
              >
                <TableCell key={alert.thresholdType} sx={{ fontSize: 16 }} component="th" scope="row">
                  {`${alert.emailSubject.replace("{{readingType}}", alert.readingType).replace("of {{value}}", "")}`}
                  <div className={styles.manage_alert_status}>
                    Status - {alert.status}
                  </div>
                  <div className={styles.manage_alert_threshold}>
                    Threshold - {alert.thresholdType && alert.thresholdType[0].toUpperCase() + alert.thresholdType.slice(1) + ' ' + alert.threshold + ' ' + getUnit(alert.readingType)}
                  </div>
                  {isMobile && (
                  <div className={styles.manage_custom_action}>
                    Custom Action - {alert.controlsTrigger && Array.isArray(alert.controlsTrigger) && alert.controlsTrigger.length ? 
                      alert.controlsTrigger.map((item: any, index: number) => (
                        <span key={index}>{item.id}{index !== alert.controlsTrigger.length - 1 ? ', ' : ''}</span>
                      ))
                      : (alert.controlsTrigger && alert.controlsTrigger[0] && alert.controlsTrigger[0].dosage ? 
                        `${Object.keys(alert.controlsTrigger[0].dosage)[0]}: ${Object.values(alert.controlsTrigger[0].dosage)[0] || '--'}` 
                        : '--'
                      )}
                  </div>
                )}
                </TableCell>
                <TableCell key={alert.status} sx={{
                  fontSize: 16,
                  '@media screen and (max-width: 48em)': {
                    display: 'none'
                  },
                }} align="left" >
                  {alert.status}
                </TableCell>
                <TableCell sx={{
                  fontSize: 16,
                  '@media screen and (max-width: 48em)': {
                    display: 'none'
                  },
                }} align="left" >{alert.thresholdType && alert.thresholdType[0].toUpperCase() + alert.thresholdType.slice(1) + ' ' + alert.threshold + ' ' + getUnit(alert.readingType)}
                </TableCell>
                <TableCell
                  key={alert.controlsTrigger}
                  sx={{
                    fontSize: 16,
                    '@media screen and (max-width: 48em)': {
                      display: 'none'
                    },
                  }}
                  align="left"
                >
                  {alert.controlsTrigger && Array.isArray(alert.controlsTrigger) && alert.controlsTrigger.length ?
                    alert.controlsTrigger.map((item: any, index: number) => (
                      <span key={index}>{item.id}{index !== alert.controlsTrigger.length - 1 ? ', ' : ''}</span>
                    ))
                    : (alert.controlsTrigger && alert.controlsTrigger[0] && alert.controlsTrigger[0].dosage ?
                      `${Object.keys(alert.controlsTrigger[0].dosage)[0]}: ${Object.values(alert.controlsTrigger[0].dosage)[0] || 'N/A'}`
                      : 'N/A'
                    )
                  }


                </TableCell>
                <TableCell align="right">
                  <MenuDots selectedAlert={alert} id={alert._id} alertData={alertData} setAlertData={setAlertData} />
                </TableCell>

              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={3} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={alertData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <div className={styles.Footer}>
        {" "}<Footer />{" "}
      </div>
    </div>
  );
}

