import React, { FC, useState } from 'react';
import { Box, Button, Popover, Typography } from '@mui/material';
import styles from "./notification.module.scss";

interface NotificationsProps {
  unread: any;
  onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined;
  id: string | undefined;
  open: boolean;
  positionX: number;
  positionY: number;
}

interface Notification {
  message: string;
  date: string;
  read: boolean;
}

const NotificationsPopOver: FC<NotificationsProps> = (props) => {
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([
    // Notification data

    {
      message: "Temp 1 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },
    {
      message: "Temp 2 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },
    {
      message: "Temp 3 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },
       {
      message: "Temp 4 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },
    {
      message: "Temp 5 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 6 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 7 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 8 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 9 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 10 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 11 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 12 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 13 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 14 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },

    {
      message: "Temp 15 exceeded the threshold",
      date: "May 15, 2023 at 10:30 AM",
      read: false
    },



    

  ]);

  const handleMarkAllAsRead = () => {
    const updatedNotifications = notifications.map(notification => ({
      ...notification,
      read: true
    }));
    setNotifications(updatedNotifications);
  };

  const initialNotificationsCount = 3;
  const displayedNotifications = showAllNotifications ? notifications : notifications.slice(0, initialNotificationsCount);  
  const ViewNotificationsCount = 10;

  const boxHeight = showAllNotifications ? 'auto' : '285px';

  return (
    <Popover
      id={props.id}
      open={props.open}
      onClose={props.onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: props.positionY, left: props.positionX }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div style={{ position: 'sticky', top: 0, backgroundColor: '#FFF', zIndex: 1 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={styles.header}
        >
          <Typography
            variant="body1"
            marginBottom="20px"
            marginTop="20px"
            fontSize="20px"
            marginLeft="20px"
            className={styles.notificationTitle}
          >
            <strong>Notifications</strong>
          </Typography>
          <Typography
            component="a"
            variant="button"
            href="#"
            className={styles.markAllRead}
            onClick={handleMarkAllAsRead}
            marginTop="20px"
            marginRight="30px"
            style={{ textTransform: 'none' }}
          >
            <strong>Mark all as read</strong>
          </Typography>
        </Box>
      </div>

      <Box
        height={boxHeight}
        width={"362px"}
        left={"0px"}
        top={"0px"}
        borderRadius={"4px"}
        padding="16px"
        display="flex"
        flexDirection="column"
        marginTop="-25px"
        className={`${styles.notificationContainer} ${styles.scrollable}`}
        >
        {/* Displayed notifications */}
        <Box display="flex" flexDirection="column" flexGrow={1}>
          {displayedNotifications.map((notification, index) => (
            <Box key={index} className={styles.notificationItem }>
              {/* Notification content */}
              <Box className={styles.notificationContent}>
                {/* Notification dot */}
                {index < 3 && !notification.read ? (
                  <div className={styles.notificationDot} />
                ) : (
                  <span />
                )}
                {/* Notification message */}
                <div className={styles.notificationMessage}>
                  <strong style={{ fontWeight: 'bold' }}>{notification.message}</strong>
                </div>
              </Box>
              {/* Notification date */}
              <div className={styles.notificationDate}>
                {notification.date}
              </div>
              {/* Divider */}
              {index !== displayedNotifications.length - 1 && (
                <hr className={styles.notificationDivider} />
              )}
            </Box>
          ))}
        </Box>

        {/* "View all notifications" button */}
        {notifications.length > initialNotificationsCount && !showAllNotifications && (
          <div onClick={() => setShowAllNotifications(true)} className={styles.viewAllNotifications}>
            <hr className={styles.notificationDivider} style={{ marginTop: '-10px' }}/>
            <strong>View all notifications</strong>
          </div>
        )}
        </Box>
        <Box >
        {/* "Show fewer notifications" button */}
        {showAllNotifications && ViewNotificationsCount && (
          <div onClick={() => setShowAllNotifications(false)} className={styles.showFewerNotifications} style={{position:'fixed', bottom: 25, backgroundColor: '#FFF'}}>
            <strong>View less</strong>
          </div>
        )}
      </Box>
    </Popover>
  );
};

export default NotificationsPopOver;
