import React, { useContext, useEffect, useState } from "react";
import { DeviceDataContext } from "./DeviceDataProvider";

export type Trend = string;
export type Axis = string;

interface IProps {
  children: React.ReactNode;
}

interface TrendConfigType {
  trendConfig: any | null;
  trendConfigChange: any;
  plotPreferences: any;
  showToast: string | null;
  onTrendConfigChange: (trend: Trend, axis: Axis, deviceID: string | null, signal: string | null) => void;
  onSignalRemove: (device: string, signal: string) => void;
  updateSignalData: (device: string, signal: string, x: string, y: Number, readingUnit: any) => void;
  updateTrendData: (trend: Trend, axis: Axis, x: Array<any>, y: Array<any>, readingUnit: any) => void;
  addTrendData: (trend: Trend, axis: Axis, data: any) => void;
  clearTrendData: (trend: Trend, axis: Axis) => void;
}

export const TrendConfigContext = React.createContext<TrendConfigType>({} as TrendConfigType);

const TrendConfigProvider = ({ children }: IProps) => {
  const [trendConfigChange, setTrendConfigChange] = useState<any>({});
  const [showToast, setshowToast] = useState<any>(null);
  const getPlotPreference = () => {
    let savedPlotPreferences: any = localStorage.getItem("plotPreferences");
    if (savedPlotPreferences) savedPlotPreferences = JSON.parse(savedPlotPreferences);
    else savedPlotPreferences = {};
    return savedPlotPreferences;
  };
  const [plotPreferences, setPlotPreferences] = useState<any>(() => getPlotPreference());
  const deviceDataContext = useContext(DeviceDataContext);
  const [trendConfig, setTrendConfig] = useState<any>({
    trend1: {
      axis1: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis2: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis3: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis4: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },

    },
    trend2: {
      axis1: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis2: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis3: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis4: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
    },
    trend3: {
      axis1: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis2: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis3: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis4: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },

    },
    trend4: {
      axis1: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis2: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis3: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },
      axis4: {
        device: null,
        signal: null,
        data: { x: [], y: [] },
        unit: null
      },

    },
  });

  useEffect(() => {
    let savedSignals = Object.keys(plotPreferences);
    console.log("Saved Signal", savedSignals);
    console.log("Plot Preference", plotPreferences);
    if (savedSignals.length > 0) {
      for (const savedSignal of savedSignals) {
        let trend = plotPreferences[savedSignal].split("-")[0];
        let axis = plotPreferences[savedSignal].split("-")[1];
        let deviceID = savedSignal.split("-")[0];
        let signal = savedSignal.split("-")[1];
        setTrendConfig((current: any) => {
          let trendConfig = Object.assign({}, current);
          trendConfig[trend][axis].device = deviceID;
          trendConfig[trend][axis].signal = signal;
          return trendConfig;
        });
      }
    }
  }, []);

  const onTrendConfigChange = (trend: Trend, axis: Axis, deviceID: string | null, signal: string | null) => {
    setPlotPreferences((current: any) => {
      if (deviceID != null && signal != null) {
        for (let signalId of Object.keys(current)) {
          if (current[signalId] === `${trend}-${axis}`) {
            deviceDataContext.removeDeviceSignal(signalId.split("-")[0], signalId.split("-")[1]);
            delete current[signalId];
            displayToast(`Signal ${signalId.split("-")[1]} removed`);
          }
        }
        current[`${deviceID}-${signal}`] = `${trend}-${axis}`;
      } else {
        delete current[`${trendConfig[trend][axis].device}-${trendConfig[trend][axis].signal}`];
        displayToast(`Signal ${trendConfig[trend][axis].signal} removed`);
      }
      localStorage.setItem("plotPreferences", JSON.stringify(current));
      return current;
    });
    setTrendConfig((current: any) => {
      let trendConfig = Object.assign({}, current);
      trendConfig[trend][axis].device = deviceID;
      trendConfig[trend][axis].signal = signal;
      return trendConfig;
    });
    setTrendConfigChange({ trend, axis, deviceID, signal });
    console.log("Trend Config",trendConfig)
  };


  const onSignalRemove = (device: string, signal: string) => {
    let signalID = `${device}-${signal}`;
    if (plotPreferences[signalID] !== undefined) {
      let trend = plotPreferences[signalID].split("-")[0];
      let axis = plotPreferences[signalID].split("-")[1];
      onTrendConfigChange(trend, axis, null, null);
    }
  };

  const updateSignalData = (device: string, signal: string, x: string, y: Number, readingUnit: any) => {
    let signalID = `${device}-${signal}`;
    if (Object.keys(plotPreferences).includes(signalID)) {
      let trend = plotPreferences[signalID].split("-")[0];
      let axis = plotPreferences[signalID].split("-")[1];
      updateTrendData(trend, axis, [x], [y], readingUnit);
    }
  };

  const updateTrendData = (trend: Trend, axis: Axis, x: Array<any>, y: Array<any>, readingUnit: any) => {
    console.log({readingUnit})
    setTrendConfig((current: any) => {
      let trendConfig = Object.assign({}, current);
      trendConfig[trend][axis].data.x = [...trendConfig[trend][axis].data.x, ...x];
      trendConfig[trend][axis].data.y = [...trendConfig[trend][axis].data.y, ...y];
      trendConfig[trend][axis].unit = readingUnit;
      return trendConfig;
    });
  };

  const addTrendData = (trend: Trend, axis: Axis, data: any) => {
    setTrendConfig((current: any) => {
      let trendConfig = Object.assign({}, current);
      trendConfig[trend][axis].data = data;
      trendConfig[trend][axis].unit = data.unit;
      return trendConfig;
    });
  };

  const clearTrendData = (trend: Trend, axis: Axis) => {
    setTrendConfig((current: any) => {
      let trendConfig = Object.assign({}, current);
      trendConfig[trend][axis].data = { x: [], y: [] };
      return trendConfig;
    });
  };

  const displayToast = (message: string) => {
    setshowToast(message);
    setTimeout(() => setshowToast(null), 3000);
  };


  const value = {
    trendConfig,
    trendConfigChange,
    plotPreferences,
    showToast,
    onTrendConfigChange,
    onSignalRemove,
    updateSignalData,
    updateTrendData,
    addTrendData,
    clearTrendData,

  };

  return <TrendConfigContext.Provider value={value}>{children}</TrendConfigContext.Provider>;
};

export default TrendConfigProvider;
