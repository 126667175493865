import { createContext, useState } from "react";
import { DeviceList, IDevice } from "../common/modal/device.modal";

interface IProps {
  children: React.ReactNode;
}

interface AppDataType {
  appdata: any;
  updateAppData: any;
  userProfile: any;
  updateUserProfile: any;
  deviceList: DeviceList;
  updateDeviceList: (deviceList: DeviceList) => void;
}

export const AppContext = createContext<AppDataType>({} as AppDataType);

const AppProvider = ({ children }: IProps) => {
  const [appdata, setAppData] = useState([]);
  const [deviceList, setDeviceList] = useState<DeviceList>(new DeviceList());
  const [userProfile, setUserProfile] = useState(null);

  const updateAppData = (data: any) => {
    setAppData(data);
  };

  const updateUserProfile = (data: any) => {
    setUserProfile(data);
  };

  const updateDeviceList = (deviceList: DeviceList) => {
    setDeviceList(deviceList);
  };

  const value = {
    appdata,
    updateAppData,
    userProfile,
    deviceList,
    updateUserProfile,
    updateDeviceList,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
export default AppProvider;
