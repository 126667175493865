
import { Routes, Route, Navigate } from "react-router-dom";
import ErrorMessage from "../../components/login/error-message";
import ForgotPassword from "../../components/login/forgot-password";
import NewPassword from "../../components/login/new-password";
import Register from "../../components/login/register";
import Successfull from "../../components/login/successfull";
import VerificationLink from "../../components/login/verification-link";
import Login from "../../components/login/login";

const Auth = () => {
  return (
    <Routes>
      <Route path="login" index element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="verificationLink" element={<VerificationLink />} />
      <Route path="error-message" element={<ErrorMessage />} />
      <Route path="successfull" element={<Successfull />} />
      <Route path="resetpassword" element={<NewPassword />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="login" />} />
    </Routes>
  );
};

export default Auth;
