import React, { FC, useContext, useState } from "react";
import styles from "./app-header.module.scss";
import notification from "../../shared/assets/icons/notification.svg";
import settings from "../../shared/assets/icons/settings.svg";
import { Notifications, NotificationsTwoTone, Settings, SettingsTwoTone } from "@mui/icons-material";
import { IconButton, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import SettingsPopOver from "../appheader-settings/settings.module";
import NotificationsPopOver from "../app-header notifications/notification.module";
import { DashboardDataContext } from "../../context/DashboardDataProvider";

interface AppHeaderProps { }

const AppHeader: FC<AppHeaderProps> = (props) => {

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255)",
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const [showSettings, setShowSettings] = useState({
    show: false,
    positionX: 190,
    positionY: 192
  });
  const [showNotifications, setshowNotifications] = useState({
    show: false,
    positionX: 170,
    positionY: 15
  });
  const dashboardDataContext = useContext(DashboardDataContext);

  const openSettings = (event: { clientX: any; clientY: any; }) => {
    setShowSettings((current) => ({
      show: true,
      positionX: event.clientX,
      positionY: event.clientY,
    }));
  }

  const closeSettings = () => {
    setShowSettings((current) => ({ ...current, show: false }));
  };

  const open = Boolean(showSettings);
  const id = open ? "simple-popover" : undefined;

  const openNotifications = (event: { clientX: any; clientY: any; }) => {
    setshowNotifications((current) => ({
      show: true,
      positionX: event.clientX,
      positionY: event.clientY,
    }));
  };

  const closeNotifications = () => {
    setshowNotifications((current) => ({ ...current, show: false }));
  };

  const open1 = Boolean(showNotifications);
  const id1 = open1 ? "simple-popover" : undefined;

  const SettingsBox = () => {
    return (
      <div>
        <SettingsPopOver id={id} open={open} onClose={closeSettings}
          positionX={showSettings.positionX}
          positionY={showSettings.positionY} />
      </div>
    );
  };

  const NotificationBox = () => {
    return (
      <div>
        <NotificationsPopOver id={id1} open={open1} onClose={closeNotifications}
        positionX={showNotifications.positionX}
        positionY={showNotifications.positionY} unread={undefined} />
      </div>
    );
  };

  const handleClick = () =>{
    window.location.reload()
  }

  
  return (
    <div className={styles.AppHeader}>
      <div className={styles.header_title} onClick={handleClick}>VANDYAM</div>
      <span className={`${styles.notification_icon} ${styles.icon}`}>
        <IconButton>
          <LightTooltip title={'Notifications'} placement="bottom">
            <img className={styles.notification_icon} src={notification} alt="" onClick={openNotifications} aria-describedby={id1} />
            {/* <Notifications onClick={openNotifications} aria-describedby={id1} /> */}
          </LightTooltip>

        </IconButton>
        <div>{showNotifications.show && NotificationBox()}</div>
      </span>
      <span className={styles.icon}>
        <IconButton>
          <LightTooltip title={'Settings'} placement="bottom">
            <img className={styles.settings} src={settings} alt="" onClick={openSettings} aria-describedby={id} />
            {/* <Settings onClick={openSettings} aria-describedby={id} /> */}
          </LightTooltip>

        </IconButton>
        <div>{showSettings.show && SettingsBox()}</div>
      </span>
    </div>
  );
};

export default AppHeader;
