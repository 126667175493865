import React, { FC, useContext } from "react";
import styles from "./plot-settings.module.scss";
import { Typography, Box, Popover, Button, createTheme, ThemeProvider } from "@mui/material";
import RemoveSignal from "../remove-signal-dialog/remove-signal-dialog";
import ExcelExport from "../DownloadData/ExcelExport"
import CSVExport from "../DownloadData/CSVExport"
import { TrendConfigContext } from "../../context/TrendConfigProvider";
import { AppContext } from "../../context/AppContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
});

interface PlotSettingsProps {
  trend: string;
  positionY: number;
  positionX: number;
  id: string | undefined;
  open: boolean;
  showPlotSettings: any;
  onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined;
}

const PlotSettings: FC<PlotSettingsProps> = (props) => {
  const trendConfigContext = useContext(TrendConfigContext);
  const [openRemoveTrend, setOpenRemoveTrend] = React.useState(false);
  const appContext = useContext(AppContext);
  console.log("appdata",appContext.appdata);

  const getAxisDeviceName = (trendNumber:number, axisNumber:number, appdata:any, trendConfigContext:any) => {
    const trendConfig = trendConfigContext.trendConfig[`trend${trendNumber}`];
    const deviceId = trendConfig[`axis${axisNumber}`]?.device;
    const deviceName = appdata.find((device:any) => device?._id === deviceId)?.name;
    return deviceName;
  };
  
  const axisCount = 4;
  const trendCount = 4;
  
  const getAxisDeviceNames = (trendNumber:number, appdata:any, trendConfigContext:any) => {
    const axisDeviceNames = [];
  
    for (let axisNumber = 1; axisNumber <= axisCount; axisNumber++) {
      const deviceName = getAxisDeviceName(trendNumber, axisNumber, appdata, trendConfigContext);
      axisDeviceNames.push(deviceName);
    }
  
    return axisDeviceNames;
  };
  
  const getAllAxisDeviceNames = (appdata:any, trendConfigContext:any) => {
    const allAxisDeviceNames = [];
     for (let trendNumber = 1; trendNumber <= trendCount; trendNumber++) {
      const axisDeviceNames = getAxisDeviceNames(trendNumber, appdata, trendConfigContext);
      allAxisDeviceNames.push(axisDeviceNames);
    }
   return allAxisDeviceNames;
  };
  
  const allAxisDeviceNames = getAllAxisDeviceNames(appContext.appdata, trendConfigContext);
   let firstArray = []
  let secondArray: never[] = []
  let thirdArray: never[] = []
  let fourthArray: never[] = []
  let fifthArray: never[] = []
  let device_id1:any
  let device_id2:any
  let device_id3:any
  let device_id4:any
  let signal1: any
  let signal2: any
  let signal3: any
  let signal4: any

 const trendNumber = parseInt(props.trend.slice(-1)) - 1;

if (trendNumber >= 0 && trendNumber < 4) {
  const trend = trendConfigContext.trendConfig[`trend${trendNumber + 1}`];

  firstArray = trend.axis1.data.x;
  secondArray = trend.axis1.data.y;
  thirdArray = trend.axis2.data.y;
  fourthArray = trend.axis3.data.y;
  fifthArray = trend.axis4.data.y;

  device_id1 = allAxisDeviceNames[trendNumber][0];
  device_id2 = allAxisDeviceNames[trendNumber][1];
  device_id3 = allAxisDeviceNames[trendNumber][2];
  device_id4 = allAxisDeviceNames[trendNumber][3];

  signal1 = trend.axis1.signal;
  signal2 = trend.axis2.signal;
  signal3 = trend.axis3.signal;
  signal4 = trend.axis4.signal;
}
 
  var arrayOfExcelObject = firstArray.map(function (value: any, index: any) {
    return {
    "Created At (Time)": value, 
    [`${device_id1}_${signal1}`]: secondArray[index],
    [`${device_id2}_${signal2}`]: thirdArray[index],
    [`${device_id3}_${signal3}`]: fourthArray[index],
    [`${device_id4}_${signal4}`]: fifthArray[index]
   };
  });

   var arrayOfCSVObject = firstArray.map(function (value: any, index: any) {
    return { 
      "Created At (Time)": value,
      [`${device_id1}_${signal1}`]: secondArray[index],
      [`${device_id2}_${signal2}`]: thirdArray[index],
      [`${device_id3}_${signal3}`]: fourthArray[index],
      [`${device_id4}_${signal4}`]: fifthArray[index]
     };;
  });
  
  return (
    <div className={styles.PlotSettings}>
      <div>
        <ThemeProvider theme={theme}>
          <Popover
            id={props.id}
            open={props.open}
            onClose={props.onClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: props.positionY, left: props.positionX }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                width: "14rem",
                height: "9rem",
                backgroundColor: "#082253",
                borderRadius: "5px",
              }}
            >
              <Typography
                textAlign="center"
                paddingTop={"0.5rem"}
                sx={{
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Plot Settings
              </Typography>

              <Typography
                paddingLeft={"7px"}
                paddingTop={"3px"}
                sx={{
                  fontWeight: "700",
                  fontSize: "15px",
                  color: "white",
                }}
              >
                Download
              </Typography>
              <div className={styles.plotBtn}>

                <ExcelExport excelData={arrayOfExcelObject} fileName={"ExcelData"} />

                <CSVExport csvData={arrayOfCSVObject} fileName={"CSVData"} />
              </div>
              <Box paddingLeft={"15px"} paddingTop={"5px"}>
                <Button variant="outlined" onClick={() => setOpenRemoveTrend(true)} size="medium" color="primary">
                  Remove all Signals
                </Button>
              </Box>
            </Box>
          </Popover>
        </ThemeProvider>
      </div>
      {openRemoveTrend && (
        <RemoveSignal handleClose={() => setOpenRemoveTrend(false)} axis={["axis1", "axis2","axis3","axis4"]} trend={props.trend} />
      )}
    </div>
  );
};
export default PlotSettings;
