import { Button } from '@mui/material';
import FileSaver from 'file-saver';
import XLSX  from "sheetjs-style";

const ExcelExport = ({excelData, fileName}: {excelData: any, fileName: string}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    const exportToExcel= async () => {
      const workSheet = XLSX.utils.json_to_sheet(excelData);
      const workBook = {Sheets: {'data':workSheet}, SheetNames:['data']};
      const excelBuffer = XLSX.write(workBook, {bookType:'xlsx', type:'array'});
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
    }

    return(
        <>
        <Button variant="text" onClick={(e)=>exportToExcel()}>
            XLS
        </Button>
        </>
    )
  
  }

  export default ExcelExport;