export interface IDevice {
  createdAt: string;
  name: string;
  id: string;
  signals: Array<ISignal>;
  controls: Array<IControl>;
  updatedAt: string;
}

export interface ISignal {
  allowedWidgetTypes: Array<string>;
  id: string;
  range: Array<{ color: string; key: string; values: Array<number> }>;
  unit: string;
  type: string;
}

export interface IControl {
  id: string;
  datatype: string;
  name: string;
  unit: string;
  type: string;
  min?: number;
  max?: number;
}

export class Device implements IDevice {
  createdAt: string;
  name: string;
  controls: Array<IControl> = [];
  id: string;
  signals: Array<ISignal> = [];
  signalsMap: any;
  updatedAt: string;

  constructor(name: string, createdAt: string, id: string, updatedAt: string) {
    this.name = name;
    this.createdAt = createdAt;
    this.id = id;
    this.updatedAt = updatedAt;
  }

  setControls(controls: IControl[]) {
    this.controls = controls;
  }

  setSignals(signals: ISignal[]) {
    this.signals = signals;
    this.signalsMap = signals.reduce((acc: any, signal: ISignal) => {
      acc[signal.id] = signal;
      return acc;
    }, {});
  }

  getSignalById(id: string) {
    return this.signalsMap[id]; //O(1)
  }

  getSignalByName(signalName: string) {
    return this.signals.find((signal: any) => signal.type === signalName); //O(1)
  }

  getSignalRangeProp(signalName: any, value: any) {
    let signal = this.signals.find((signal: any) => signal.type === signalName);
    let _rangeProp = { color: "pink", key: "Normal", values: [0, 0] };
    if (signal) {
      _rangeProp =
        signal.range.find((rangeProp: any) => value < rangeProp.values[1]) || signal.range[signal.range.length - 1];
    }
    return _rangeProp;
  }

  print(): void {
    console.log(Device);
  }
}

export class DeviceList {
  private allDevices: IDevice[];
  private deviceMap: any;

  constructor(deviceList: IDevice[] = []) {
    this.allDevices = deviceList;
    this.deviceMap = deviceList.reduce((acc: any, device: IDevice) => {
      acc[device.id] = device;
      return acc;
    }, {});
  }

  public getDeviceById(id: string) {
    if (!id) return {};
    return this.deviceMap[id];
  }

  public getSignalsByDeviceId(id: string) {
    if (!id || !this.deviceMap[id]) return [];
    return this.deviceMap[id].signals;
  }

  public getAllDevices() {
    return this.allDevices;
  }

  public setDevices(deviceList: IDevice[]) {
    this.allDevices = deviceList;
    this.deviceMap = deviceList.reduce((acc: any, device: IDevice) => {
      acc[device.id] = device;
      return acc;
    }, {});
  }

  public addDevice(device: IDevice) {
    this.allDevices.push(device);
    this.deviceMap[device.id] = device;
  }
}
