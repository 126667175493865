import React, { useContext, useEffect, useState } from "react";
import { DeviceDataContext } from "./DeviceDataProvider";

interface IProps {
  children: React.ReactNode;
}

interface DashboardDataType {
  deviceData: any | null;
  addWidgetPanel: boolean;
  showWidgetPreferenceDialog: any;
  widgetPreferences: any;
  selectedWidgets: any;
  dashboardWidgets: any;
  widgetLiveData: any;
  toggleAddWidgetPanel: () => void;
  onShowWidgetPreferenceDialog: (device: any, deviceId: any, signalName: any, allowedWidgets: any, range: any) => void;
  onSaveWidgetPreference: (selectedwidget: any) => void;
  onLoadProfileResponse: (device: any, deviceId: any, signalName: any, allowedWidgets: any, range: any) => void
  onRemoveWidget: (widgetId: string) => void;
  onCloseWidgetPreferenceDialog: () => void;
  updateWidgetData: (device: any, signal:any, data: any, readingUnit: any) => void;
}

export const DashboardDataContext = React.createContext<DashboardDataType>({} as DashboardDataType);

const DashboardDataProvider = ({ children }: IProps) => {
  const [deviceData, setDeviceData] = useState<any>({});
  const [addWidgetPanel, setAddWidgetPanel] = useState<any>(true);
  const [showWidgetPreferenceDialog, setShowWidgetPreferenceDialog] = useState<any>(false);
  const [widgetPreferences, setWidgetPreferences] = useState<any>({});
  const [selectedWidgets, setSelectedWidgets] = useState<any>({});
  const [selectedHealthStat, setSelectedHealthStat] = useState<any>({});
  const [dashboardWidgets, setDashboardWidgets] = useState<any>({});
  const [dashboardHealthStat, setDashBoardHealthStat] = useState<any>({});
  const [widgetLiveData, setWidgetLiveData] = useState<any>({ device: "", signal: "", data: "--", readingUnit: "" });

  useEffect(() => {
    let dashboardWidgets = Object.keys(selectedWidgets).reduce((acc: any, current: any) => {
      if (acc[current.split("-")[0]] && acc[current.split("-")[0]].length > 0) acc[current.split("-")[0]].push(current);
      else acc[current.split("-")[0]] = [current];
      return acc;
    }, {});
    setDashboardWidgets(dashboardWidgets);
    console.log(dashboardWidgets);
  }, [selectedWidgets]);

  const toggleAddWidgetPanel = () => {
    setAddWidgetPanel((current: boolean) => !current);
  };

  const onShowWidgetPreferenceDialog = (device: any, deviceId: any, signalName: any, allowedWidgets: any, range:any) => {
    setShowWidgetPreferenceDialog(true);
    setWidgetPreferences({ device, deviceId, signalName, allowedWidgets, range });
  };

  const onCloseWidgetPreferenceDialog = () => {
    setShowWidgetPreferenceDialog(false);
    setWidgetPreferences(null);
  };

  const onSaveWidgetPreference = (selectedwidget: any) => {
    setWidgetPreferences((current: any) => {
      return { ...current, selectedwidget };
    });
    console.log("widgetPreferences",widgetPreferences)
    setSelectedWidgets((current: any) => {
      return {
        ...current,
        [`${widgetPreferences.deviceId}-${widgetPreferences.signalName}-${selectedwidget}`]: widgetPreferences,
      };
    });
    setShowWidgetPreferenceDialog(false);
    setAddWidgetPanel(false);
  };

  const onLoadProfileResponse = (device: any, deviceId: any, signalName: any, allowedWidgets: any, range:any) =>{
    setSelectedWidgets({ device, deviceId, signalName, allowedWidgets, range });
    setShowWidgetPreferenceDialog(false);
    setAddWidgetPanel(true);
  }

  const onRemoveWidget = (widgetId: any) => {
    setSelectedWidgets((current: any) => {
      let selectedWidgets = Object.assign({}, current);
      delete selectedWidgets[widgetId];
      return selectedWidgets;
    });
  };

  const updateWidgetData = (device: any, signal:any, data: any, readingUnit: any) => {
    setWidgetLiveData({ device, signal, data, readingUnit });
  };

  const value = {
    deviceData,
    addWidgetPanel,
    showWidgetPreferenceDialog,
    widgetPreferences,
    selectedWidgets,
    dashboardWidgets,
    widgetLiveData,
    toggleAddWidgetPanel,
    onShowWidgetPreferenceDialog,
    onCloseWidgetPreferenceDialog,
    onRemoveWidget,
    onSaveWidgetPreference,
    onLoadProfileResponse,
    updateWidgetData,
  };

  return <DashboardDataContext.Provider value={value}>{children}</DashboardDataContext.Provider>;
};

export default DashboardDataProvider;
