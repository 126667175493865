import React, { FC, useContext, useEffect, useState } from "react";
import axiosClient from "../../common/apis/axiosClient";
import { TrendConfigContext } from "../../context/TrendConfigProvider";
import TrendPlot from "../trend-plot/trend-plot";
import styles from "./trend-data-reader.module.scss";
import socketIOClient from "socket.io-client";
import Snackbar from "@mui/material/Snackbar";
//import jwtDecode from 'jwt-decode'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Button } from "@mui/material";

const ENDPOINT = "wss://app.vandyam.com";

interface TrendDataReaderProps {}
// interface JwtPayload {
//   id: string;
// }

const TrendDataReader: FC<TrendDataReaderProps> = () => {
  const trendConfigContext = useContext(TrendConfigContext);
  const [loadingText, setLoadingText] = useState({ trend1: "", trend2: "",trend3: "", trend4: "" });
  const [websocketConnected, setWebsocketConnected] = useState(false);
  
  const getID = () : string => {
    const token = localStorage.getItem('token');
    console.log("tokens",token);
    if (!token) return "";
  const decodedToken: any = jwtDecode(token);
  if (decodedToken && (decodedToken.exp as number) < new Date().getTime()) {
    return decodedToken.id as string;
  }
  return "";
  }
  

  useEffect(() => {
    let savedPlotPreferences: any = localStorage.getItem("plotPreferences");
    if (savedPlotPreferences) {
      savedPlotPreferences = JSON.parse(savedPlotPreferences);
      let savedSignals = Object.keys(savedPlotPreferences);
      if (savedSignals.length > 0) {
        for (const savedSignal of savedSignals) {
          let trend = savedPlotPreferences[savedSignal].split("-")[0];
          let axis = savedPlotPreferences[savedSignal].split("-")[1];
          let deviceID = savedSignal.split("-")[0];
          let signal = savedSignal.split("-")[1];
          if (trend && axis) fetchPlotData({ trend, axis, deviceID, signal });
        }
      }
      if (!websocketConnected) {
        setWebsocketConnected(true);
        const socket = socketIOClient(ENDPOINT, {
          transports: ["websocket", "polling", "flashsocket"],
          upgrade: false,
          auth: {
            token: localStorage.getItem("token"),
          },
        });
        socket.on("connect", () => {
          console.log("inside socket connect");
          socket.emit("joinRoom", "abcd");
        });

        socket.on("disconnect", () => {
          console.log("inside socket disconnect");
          setWebsocketConnected(false);
        });

        socket.on(getID(), (data) => {
          if (typeof data !== "string") {
            console.log("socket data", data);
            trendConfigContext.updateSignalData(
              data.device,
              data.readingType,
              data.createdAt,
              data.reading,
              data.readingUnit
            );
          }
        });
        return () => {
          console.log("inside useEffect cleanup function");
          socket.off("connect");
          socket.off("disconnect");
          socket.off(getID());
        };
      }
    }
  }, []);

  useEffect(() => {
    let config = trendConfigContext.trendConfigChange;
    if (config.trend && config.axis) {
      fetchPlotData(config);
      if (!websocketConnected) {
        setWebsocketConnected(true);
        const socket = socketIOClient(ENDPOINT, {
          transports: ["websocket", "polling", "flashsocket"],
          upgrade: false,
          auth: {
            token: localStorage.getItem("token"),
          },
        });

        socket.on("connect", () => {
          console.log("inside socket connect");
          socket.emit("joinRoom", "abcd");
        });

        socket.on("disconnect", () => {
          console.log("inside socket disconnect");
          setWebsocketConnected(false);
        });

        socket.on(getID(), (data) => {
          if (typeof data !== "string") {
            console.log("socket data", data);
            trendConfigContext.updateSignalData(
              data.device,
              data.readingType,
              data.createdAt,
              data.reading,
              data.readingUnit
            );
          }
        });
        return () => {
          console.log("inside useEffect cleanup function");
          socket.off("connect");
          socket.off("disconnect");
          socket.off(getID());
        };
      }
    }
  }, [trendConfigContext.trendConfigChange]);

  const fetchPlotData = async (config: any) => {
    let data = { x: [], y: [], unit: null };
    if (config.deviceID != null && config.signal != null) {
      trendConfigContext.clearTrendData(config.trend, config.axis);
      setLoadingText((current: any) => {
        let loadingText = Object.assign({}, current);
        loadingText[config.trend] = `Loading: ${config.signal}`;
        return loadingText;
      });
      data = await getPlotDataFromServer(config.deviceID, config.signal);
      setLoadingText((current: any) => {
        let loadingText = Object.assign({}, current);
        loadingText[config.trend] = "";
        return loadingText;
      });
      trendConfigContext.addTrendData(config.trend, config.axis, data);
    } else {
      trendConfigContext.clearTrendData(config.trend, config.axis);
    }
  };

  const getPlotDataFromServer = async (deviceID: string, readingType: string) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `${token}` },
    };
    const response = await axiosClient.get(`/api/deviceData/${deviceID}?readingType=${readingType}&limit=100`, config);
    console.log("Trend data Reader", response);
    let plotData = response.data.device
      .sort(function (a: any, b: any) {
        return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
      })
      .reduce(
        (acc: any, data: any) => {
          acc.x.push(data.createdAt);
          acc.y.push(data.reading);
          acc.unit = data.readingUnit;
          return acc;
        },
        { x: [], y: [], unit: null }
      );
    return plotData;
  };

  return (

    <div className={styles.TrendDataReader}>
      
      {(trendConfigContext.trendConfig.trend1.axis1.signal || trendConfigContext.trendConfig.trend1.axis2.signal || trendConfigContext.trendConfig.trend1.axis3.signal || trendConfigContext.trendConfig.trend1.axis4.signal ) && (
        <div className={styles.plot}>
          <span className={styles.loading_text}>{loadingText.trend1}</span>
          <TrendPlot trendTitle={"trend1"} />
        </div>
      )}
      {(trendConfigContext.trendConfig.trend2.axis1.signal || trendConfigContext.trendConfig.trend2.axis2.signal || trendConfigContext.trendConfig.trend2.axis3.signal || trendConfigContext.trendConfig.trend2.axis4.signal ) && (
        <div className={styles.plot}>
          <span className={styles.loading_text}>{loadingText.trend2}</span>
          <TrendPlot trendTitle={"trend2"} />
        </div>
      )}
      
      
      {(trendConfigContext.trendConfig.trend3.axis1.signal || trendConfigContext.trendConfig.trend3.axis2.signal || trendConfigContext.trendConfig.trend3.axis3.signal || trendConfigContext.trendConfig.trend3.axis4.signal ) && (
        <div className={styles.plot}>
          <span className={styles.loading_text}>{loadingText.trend3}</span>
          <TrendPlot trendTitle={"trend3"} />
        </div>
      )}
      {(trendConfigContext.trendConfig.trend4.axis1.signal || trendConfigContext.trendConfig.trend4.axis2.signal || trendConfigContext.trendConfig.trend4.axis3.signal || trendConfigContext.trendConfig.trend4.axis4.signal ) && (
        <div className={styles.plot}>
          <span className={styles.loading_text}>{loadingText.trend4}</span>
          <TrendPlot trendTitle={"trend4"} />
        </div>
      )}
     

      <Snackbar
        open={trendConfigContext.showToast != null}
        autoHideDuration={6000}
        message={trendConfigContext.showToast}
      />
    </div>
  );
};

export default TrendDataReader;
