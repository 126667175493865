import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../Auth';
import axiosClient from '../../common/apis/axiosClient';
import styles from './login.module.scss';

interface LoginProps { }

const NewPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.path || '/auth/resetpassword';
  const redirectPath1 = location.state?.path || '/auth/successfull';
  const redirectPath2 = location.state?.path || '/auth/error-message';

  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const [passwordError, setPasswordError] = useState('');

  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [enteredEmailTouched, setEnteredEmailTouched] =
    useState<boolean>(false);
  const [enteredPassword1, setEnteredPassword1] = useState<string>("");
  const [enteredPassword1Touched, setEnteredPassword1Touched] =
    useState<boolean>(false);
  const [enteredPassword2, setEnteredPassword2] = useState<string>("");
  const [enteredPassword2Touched, setEnteredPassword2Touched] =
    useState<boolean>(false);


  const validateEmail = (inputEmail: string): boolean => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    return regex.test(inputEmail);
  };

  const validatePassword1 = (password: string): boolean => {
    const trimmedPassword: string = password.trim();
    return trimmedPassword !== "" && trimmedPassword.length >= 8;
  };
  const validatePassword2 = (password: string): boolean => {
    const trimmedPassword: string = password.trim();
    return trimmedPassword !== "" && trimmedPassword.length >= 8;
  };

  // const enteredEmailIsValid: boolean = validateEmail(enteredEmail);
  // const entetedEmailInputIsInvalid: boolean =
  //   !enteredEmailIsValid && enteredEmailTouched;

  const enteredPassword1IsValid: boolean = validatePassword1(enteredPassword1);
  const enteredPassword1InputIsInvalid: boolean =
    !enteredPassword1IsValid && enteredPassword1Touched;

  const enteredPassword2IsValid: boolean = validatePassword2(enteredPassword2);
  const enteredPassword2InputIsInvalid: boolean =
    !enteredPassword2IsValid && enteredPassword2Touched;

  let formIsValid: boolean = false;
  const element: any = document.getElementById("errorElement");
  if (enteredPassword1IsValid && enteredPassword2IsValid) {
    formIsValid = true;
  }

  //const [passwordError, setPasswordError] = useState('');

  // let passwordIsMatched: boolean = false;

  // if (enteredPassword1 === enteredPassword2){
  //   passwordIsMatched = true;
  // }
  // else{
  //   setPasswordError('New password and confirm password did not match.');
  // }




  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEmail(event.target.value);
  };

  const emailInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setEnteredEmailTouched(true);
  };

  const passwordChangeHandler1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnteredPassword1(event.target.value);
    // if(enteredPassword1 === enteredPassword2){
    //   element.innerHTML = "";
    // } else {
    //   element.innerHTML = "New password and Confirm password not matching ";
    // }
  };

  const passwordInputBlurHandler1 = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    setEnteredPassword1Touched(true);
  };

  const passwordChangeHandler2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnteredPassword2(event.target.value);
    // if(enteredPassword1 === enteredPassword2){
    //   element.innerHTML = "";
    // } else {
    //   element.innerHTML = "New password and Confirm password not matching ";
    // }
  };

  const passwordInputBlurHandler2 = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    setEnteredPassword2Touched(true);
  };

  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    const tokenParam = new URLSearchParams(location.search).get("token");
    setToken(tokenParam);
  }, []);


  const auth = useAuth()

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!enteredPassword1 || !enteredPassword2) {
      setPasswordError('Password cannot be blank');
      return;
    }
    if (enteredPassword1.length < 8) {
      setPasswordError('Not a valid Password');
      return;
    }

    if (enteredPassword1 !== enteredPassword2) {
      setPasswordError('Password Not Matched');
      return;
    } else {


      //const onSuccessSignIn = () => {
      axiosClient
        .post("api/resetPassword", {
          email: getEmail(),
          password: enteredPassword1,
          token: getToken(),
        })
        .then((response) => {
          console.log("loginrespData", response);
          if (response.status === 200) {
            console.log("entered");
            auth.login(response.data.user);
            navigate(redirectPath1, { replace: true });
          }
        })
        .catch((err: any) => {
          console.log("nentered");
          // auth.login(response.data.user)
          navigate(redirectPath2, { replace: true });
        });
      // };

    }
  }




  // const auth = useAuth()

  // const onSuccessSignIn = () => {
  //   axiosClient
  //     .post("api/resetPassword", {
  //       email: getEmail(),
  //       password: enteredPassword1,
  //       token : getToken()
  //     })
  //     .then((response) => {
  //       console.log("loginrespData", response);
  //       localStorage.setItem("token", response.data.token);
  //       if (response.status === 200) {
  //         console.log("enterd");
  //         auth.login(response.data.user)
  //         navigate(redirectPath1, {replace: true});
  //       }
  //     })
  //     .catch((err:any) => {
  //       console.log("nenterd");
  //       //auth.login(response.data.user)
  //       navigate(redirectPath2, {replace: true});
  //     });
  // };




  const getToken = (): string | null => {
    const token: string | null = new URLSearchParams(location.search).get("token");
    return token;
  }

  const getEmail = (): string => {
    //console.log('path', location);
    const emailId: any = new URLSearchParams(location.search).get("email");
    //console.log(emailId);
    return emailId;
  }
  if (getEmail() && getToken()) {
    return (
      <div className={styles.Login} style={{ margin: "8rem 0 0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
        <form onSubmit={handleSubmit}>
          <div>
            <Box className={styles.credBox}>
              <div className={styles.title}>
                <Typography variant="h3" className={styles.loginTxt} >
                  Email Verified
                </Typography>
                <Typography className={styles.captionTxt} variant="body1">
                  Please create password
                </Typography>
              </div>
              <div className={styles.credentials}>
                {/* <div>
                    {getToken()}
                </div>
                <div>
                    {getEmail()}
                </div> */}
                <div>
                  <Typography variant="h5" style={{ marginTop: '25px', }}>Create Password</Typography>
                  <TextField
                    id="password"
                    name="password"
                    variant="outlined"
                    margin="normal"
                    className={styles.passBox}
                    onChange={passwordChangeHandler1}
                    onBlur={passwordInputBlurHandler1}
                    style={{ width: "375px" }}
                    type={showPassword1 ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={enteredPassword1InputIsInvalid}
                    helperText={(enteredPassword1InputIsInvalid && 'Not a valid Password') || " "}
                  />
                </div>
                <div>
                  <Typography variant="h5" style={{ marginTop: '5px' }}>Confirm Password</Typography>
                  <TextField
                    id="password"
                    name="password"
                    variant="outlined"
                    margin="normal"

                    className={styles.passBox}
                    onChange={passwordChangeHandler2}
                    onBlur={passwordInputBlurHandler2}
                    type={showPassword2 ? 'text' : 'password'}
                    style={{ width: "375px" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={enteredPassword2InputIsInvalid}
                    helperText={(enteredPassword2InputIsInvalid && 'Not a valid Password') || " "}
                  />
                </div>
              </div>
              <div>
                {passwordError && <div className={styles.mismatch}>{passwordError}</div>}
              </div>
              <div>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  name="loginBtn"
                  style={{ width: "375px", marginTop: "10px" }}
                  //onClick={onSuccessSignIn}
                  className={styles.loginBtn}
                  type="submit"

                >Register</Button>
              </div>
              {/* <div className={styles.btn_verification} >
              <Button
                variant="contained"
                size="large"
                color="primary"
                name="loginBtn"
                //onClick={onSuccessSignIn}
                className={styles.loginBtn}
              >
                REGISTER
              </Button>
            </div> */}
              <div className={styles.forgot_pwd} style={{ marginTop: "1.25rem" }}>
                <a href="/" style={{ fontSize: '23px' }}>Back to Login Page</a>
              </div>
              {/* <div className={styles.new_user}>
            <a href="/register">New user? Register here</a>
            </div> */}
            </Box>
          </div>
        </form>
      </div>

    );
  } else {
    return (<div>
      Some error message
    </div>);
  }
};

export default NewPassword;
