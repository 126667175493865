import React, { FC, useState, useContext, useEffect } from "react";
import styles from "./signal-selection.module.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DeviceDataContext } from "../../context/DeviceDataProvider";
import { TrendConfigContext } from "../../context/TrendConfigProvider";
interface SignalDialogProps {}

const SignalDialog: FC<SignalDialogProps> = () => {
  const [trend, setTrend] = useState("trend1");
  const [axis, setAxis] = useState("axis1");
  const {
    showPreferenceDialogBox,
    handlePreferenceDialogBox,
    checkBoxCheckedData,
    onTrendShowPlot,
    onInputCheckboxChange,
  } = useContext(DeviceDataContext);
  const { onTrendConfigChange } = useContext(TrendConfigContext);

  const handleTrendChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setTrend(event.target.value);
  };

  const handleAxisChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setAxis(event.target.value);
  };

  const onPreferenceCancelHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handlePreferenceDialogBox(false);
    onInputCheckboxChange(
      null,
      checkBoxCheckedData.deviceId,
      checkBoxCheckedData.deviceName,
      checkBoxCheckedData.signalId,
      checkBoxCheckedData.signalName
    );
  };

  const onPreferenceConfirmHandler = () => {
    handlePreferenceDialogBox(false);
    onTrendConfigChange(trend, axis, checkBoxCheckedData.deviceId, checkBoxCheckedData.signalName);
    onTrendShowPlot(true);
  };

  useEffect(() => {
    console.log(showPreferenceDialogBox);
  }, [showPreferenceDialogBox]);

  return (
    <div className={styles.SignalDialog}>
      <div>
        <Dialog open={showPreferenceDialogBox}>
          <DialogTitle>Plot Preference</DialogTitle>
          <DialogContent>
            <DialogContentText variant="subtitle1">Choose a plotting preference for a signal</DialogContentText>
            <div className={styles.deviceName}>
              <Typography paddingRight={"2rem"} paddingLeft={"0.5rem"}>
                Device Name
              </Typography>
              <Typography>{checkBoxCheckedData.deviceName}</Typography>
            </div>
            <div className={styles.signalName}>
              <Typography paddingRight={"2rem"} paddingLeft={"0.5rem"}>
                Signal Name
              </Typography>
              <Typography>{checkBoxCheckedData.signalName}</Typography>
            </div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <div className={styles.Trend}>
                <Typography paddingRight={"2.5rem"}>Trend Chart</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel>Select Trend</InputLabel>
                  <Select
                    labelId="trend"
                    id="trend"
                    value={trend}
                    onChange={handleTrendChange}
                    label="Trend"
                    className={styles.trendMenu}
                  >
                    <MenuItem value={"trend1"}>Trend 1</MenuItem>
                    <MenuItem value={"trend2"}>Trend 2</MenuItem>
                    <MenuItem value={"trend3"}>Trend 3</MenuItem>
                    <MenuItem value={"trend4"}>Trend 4</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={styles.Axis}>
                <Typography paddingRight={"5.9rem"}>Axis</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel>Select Axis</InputLabel>
                  <Select
                    labelId="axis"
                    id="axis"
                    value={axis}
                    onChange={handleAxisChange}
                    label="Axis"
                    className={styles.axisMenu}
                  >
                    <MenuItem value={"axis1"}>Axis 1</MenuItem>
                    <MenuItem value={"axis2"}>Axis 2</MenuItem>
                    <MenuItem value={"axis3"}>Axis 3</MenuItem>
                    <MenuItem value={"axis4"}>Axis 4</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onPreferenceCancelHandler(event)}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={onPreferenceConfirmHandler}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default SignalDialog;