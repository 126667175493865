import { useContext, useEffect, useState, useSyncExternalStore } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AuthProvider } from "./Auth";
import { AppContext } from "./context/AppContext";
import axiosClient from "./common/apis/axiosClient";
import { Device, DeviceList } from "./common/modal/device.modal";
import { useDeviceStore } from "./common/store/app.store";
import Auth from "./modules/auth";
import "./App.scss";
import Project from "./modules/project";
import { useRefreshToken } from "./hooks/useRefreshToken";
import React from "react";
import axios from "axios";

function App() {
  const [devices, setDevices] = useDeviceStore((state: any) => [
    state.devices,
    state.setDevices,
  ]);
  const [appVersion, setAppVersion] = useState("");
  const [version, setversion] = useState("");
  const appContext = useContext(AppContext);
  const refreshToken = useRefreshToken();
  console.log("[App Component Load]");

 
  useEffect(() => {
    const fetchAppData = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: { Authorization: `${token}` },
        };
        const response = await axiosClient.get("api/device", config);
        let deviceList = new DeviceList();
        for (let i = 0; i < response.data.devices.length; i++) {
          let deviceRaw = response.data.devices[i];
          let device = new Device(
            deviceRaw.name,
            deviceRaw.createdAt,
            deviceRaw._id,
            deviceRaw.updatedAt
          );
          device.setControls(deviceRaw.controls);
          device.setSignals(deviceRaw.parameters);
          deviceList.addDevice(device);
        }
  
        console.log("===========", deviceList.getAllDevices());
        appContext.updateDeviceList(deviceList);
        appContext.updateAppData(response.data.devices);
      } catch (error) {
        console.error(error);
      }
    };
    
    fetchAppData();
  }, [refreshToken]);

  return (
    <div className="main_container">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            {refreshToken && (
              <>
                <Route path="/*" element={<Project />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}
            <Route path="/auth/*" element={<Auth />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
