import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./asset-tracker.module.scss";
import { FormControl, Select, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import { AppContext } from "../../context/AppContext";

const AssetTracker = () => {

  const appContext = useContext(AppContext);
  const [selectedDeviceId, setSelectedDeviceId] = useState<any>(appContext.deviceList.getAllDevices()[0]?.id);
  const [deviceError, setDeviceError] = useState<any>();


  const handleDeviceChange = (event: any) => {
    setSelectedDeviceId(event.target.value);
    setDeviceError(null);
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.dropdownContainer}>
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: 150,
            borderRadius: "2px",
            paddingRight: "15px",
            paddingBottom: "2rem",
          }}
        >
          <InputLabel>Device</InputLabel>
          <Select onChange={handleDeviceChange} value={selectedDeviceId} required>
            {appContext.deviceList.getAllDevices().map((device: any) => {
              return (
                <MenuItem value={device.id} key={device.name}>
                  {device.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error>
            {deviceError && "Device not selected!"}
          </FormHelperText>
        </FormControl>
      </div>
      <div className={styles.google_Map}>
            Google Map
      </div>
    </div >
  );
};

export default AssetTracker;