import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TextField, Box, Typography, Button } from "@mui/material";
import styles from "./register.module.scss";
import axiosClient from "../../common/apis/axiosClient";
import { useAuth } from "../../Auth";


interface RegisterProps {}

const Register: React.FC<RegisterProps> = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const redirectPath = location.state?.path || "/auth/verificationLink";
  const redirectPath1 = location.state?.path || "/auth/error-message";

  const [enteredName, setEnteredName] = useState<string>("");
  const [enteredNameTouched, setEnteredNameTouched] = useState<boolean>(false);

  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [enteredEmailTouched, setEnteredEmailTouched] = useState<boolean>(false);

  const [enteredMobile, setEnteredMobile] = useState<string>("");
  const [enteredMobileTouched, setEnteredMobileTouched] = useState<boolean>(false);

  const [enteredDeviceId, setEnteredDeviceId] = useState<string>("");
  const [enteredDeviceIdTouched, setEnteredDeviceIdTouched] = useState<boolean>(false);



  const validateName = (inputName: string): boolean => {
    let regex = new RegExp("[A-Za-z ]{3,29}$");
    return regex.test(inputName);
  };

  const validateEmail = (inputEmail: string): boolean => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    return regex.test(inputEmail);
  };

  const validateMobile = (inputMobile: string): boolean => {
    let regex = new RegExp("^[6-9][0-9]{9}$");
    return regex.test(inputMobile);
  };

  const enteredNameIsValid: boolean = validateName(enteredName);
  const entetedNameInputIsInvalid: boolean = !enteredNameIsValid && enteredNameTouched;

  const enteredEmailIsValid: boolean = validateEmail(enteredEmail);
  const entetedEmailInputIsInvalid: boolean = !enteredEmailIsValid && enteredEmailTouched;

  const enteredMobileIsValid: boolean = validateMobile(enteredMobile);
  const entetedMobileInputIsInvalid: boolean = !enteredMobileIsValid && enteredMobileTouched;



  const enteredDeviceIdIsValid: boolean = enteredDeviceId.trim() !== "";
  const entetedDeviceIdInputIsInvalid: boolean = !enteredDeviceIdIsValid && enteredDeviceIdTouched;


  let formIsValid: boolean = false;

  if (enteredEmailIsValid && enteredNameIsValid && enteredMobileIsValid) {
    formIsValid = true;
  }

  const handleRegisterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    if (formIsValid && enteredDeviceId.trim() !== "") {
      onSuccessSignUp();
    }
  };

  const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredName(event.target.value);
  };

  const nameInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setEnteredNameTouched(true);
  };

  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEmail(event.target.value);
  };

  const emailInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setEnteredEmailTouched(true);
  };

  const mobileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredMobile(event.target.value);
  };

  const mobileInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setEnteredMobileTouched(true);
  };

  const deviceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredDeviceId(event.target.value);
  };

  const deviceInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setEnteredDeviceIdTouched(true);
  };

  const auth = useAuth();

  const onSuccessSignUp = () => {
    axiosClient
      .post("api/signup", {
        email: enteredEmail,
        name: enteredName,
        mobile: enteredMobile,
        deviceId: enteredDeviceId,
      })
      .then((response) => {
        console.log("regist,errespData", response);
        
        if (response.status === 200) {
          console.log("enterd");
          auth.login(response.data.user);
          navigate(redirectPath, { replace: true });
        }
      })
      .catch((error: any) => {
        console.log("not enterd");
        //auth.login(response.data.user)
        navigate(redirectPath1, { replace: true });
      });
  };

  return (
    <div className={styles.Login}>
      <form>
        <div>
          <Box className={styles.credBox}>
            <div className={styles.title}>
              <Typography variant="h3" className={styles.loginTxt} style={{ fontSize: "1.5rem" }}>
                Register
              </Typography>
              <Typography variant="body2" className={styles.captionTxt}>
                Please enter your details
              </Typography>
            </div>
            <div className={styles.credentials} style={{ padding: "1rem" }}>
              <div>
                <Typography variant="h5" style={{ fontSize: "1.2rem" }}>
                  Name
                </Typography>
                <TextField
                  id="name"
                  name="name"
                  variant="outlined"
                  type="name"
                  margin="normal"
                  className={styles.emailBox}
                  onChange={nameChangeHandler}
                  onBlur={nameInputBlurHandler}
                  error={entetedNameInputIsInvalid}
                  helperText={(entetedNameInputIsInvalid && "Not a valid Name") || " "}
                />
              </div>
              <div className={styles.email}>
                <Typography variant="h5" style={{ fontSize: "1.2rem" }}>
                  Email
                </Typography>
                <TextField
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  margin="normal"
                  className={styles.emailBox}
                  onChange={emailChangeHandler}
                  onBlur={emailInputBlurHandler}
                  error={entetedEmailInputIsInvalid}
                  helperText={(entetedEmailInputIsInvalid && "Not a valid Email") || " "}
                />
              </div>
              <div className={styles.mob}>
                <Typography variant="h5" style={{ fontSize: "1.2rem" }}>
                  Mobile
                </Typography>
                <TextField
                  id="mobile"
                  name="mobile"
                  variant="outlined"
                  type="mobile"
                  margin="normal"
                  className={styles.emailBox}
                  onChange={mobileChangeHandler}
                  onBlur={mobileInputBlurHandler}
                  error={entetedMobileInputIsInvalid}
                  helperText={(entetedMobileInputIsInvalid && "Not a valid mobile number") || " "}
                />
              </div>
              <div className={styles.mob}>
                <Typography variant="h5" style={{ fontSize: "1.2rem" }}>
                  Device ID
                </Typography>
                <TextField
                  id="deviceId"
                  name="deviceId"
                  variant="outlined"
                  type="deviceId"
                  margin="normal"
                  className={styles.emailBox}
                  onChange={deviceChangeHandler}
                  onBlur={deviceInputBlurHandler}
                  error={entetedDeviceIdInputIsInvalid}
                  helperText={(entetedDeviceIdInputIsInvalid && "Device ID field cannot be empty") || " "}
                />
              </div>
              <div>

                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  name="loginBtn"
                  className={styles.loginBtn}
                  disabled={
                    !enteredEmailIsValid ||
                    !enteredNameIsValid ||
                    !enteredMobileIsValid ||
                    enteredEmail.trim() === "" ||
                    enteredName.trim() === "" ||
                    enteredMobile.trim() === "" ||
                    enteredDeviceId.trim() === "" ||
                    entetedEmailInputIsInvalid ||
                    entetedNameInputIsInvalid ||
                    entetedMobileInputIsInvalid ||
                    entetedDeviceIdInputIsInvalid
                  }
                  onClick={handleRegisterClick}
                >
                  REGISTER
                </Button>
              </div>
              <div className={styles.new_user}>
                <a href="/">Already a user? LOGIN</a>
              </div>
            </div>
          </Box>
        </div>
      </form>
    </div>
  );
};  

export default Register;