  import React, { FC, useEffect, useState, useContext, Fragment } from "react";
  import styles from "./device-selection.module.scss";
  import TextField from "@mui/material/TextField";
  import SearchIcon from "@mui/icons-material/Search";
  import InputAdornment from "@mui/material/InputAdornment";
  import { TreeView, TreeItem } from '@mui/x-tree-view';
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import ChevronRightIcon from "@mui/icons-material/ChevronRight";
  import axiosClient from "../../common/apis/axiosClient";
  import { Checkbox, FormControlLabel } from "@mui/material";
  import { DeviceDataContext } from "../../context/DeviceDataProvider";
  import { TrendConfigContext } from "../../context/TrendConfigProvider";
  import { AppContext } from "../../context/AppContext";
  import socketIOClient from "socket.io-client";

  interface DeviceSelectionProps {}

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const DeviceSelection: FC<DeviceSelectionProps> = () => {
    const [deviceData, setDeviceData] = useState([]);
    const [searchedValue, setsearchedValue] = React.useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const { checkBoxSelectedSignals, onInputCheckboxChange } =
      useContext(DeviceDataContext);
    const trendConfigContext = useContext(TrendConfigContext);
    const appContext = useContext(AppContext);

    console.log("checkBoxSelectedSignals value ", checkBoxSelectedSignals);
    console.log(
      "localSorage value ",
      localStorage.getItem("selectedSignalsCheckbox")
    );

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value.toLowerCase();
      setsearchedValue(searchValue);
    };

    useEffect(() => {
      const filteredData = appContext.appdata.filter((device: any) => {
        return device.name.toLowerCase().includes(searchedValue);
      });
      setDeviceData(filteredData);
    }, [appContext.appdata, searchedValue]);

    
    
    const onInputCheckboxChangeHandler = (
      event: React.ChangeEvent<HTMLInputElement> | null,
      deviceId: any,
      deviceName: any,
      signalId: any,
      signalType: any
    ) => {
      if (checkBoxSelectedSignals.includes(`${deviceId}-${signalType}`))
        trendConfigContext.onSignalRemove(deviceId, signalType);
      onInputCheckboxChange(event, deviceId, deviceName, signalId, signalType);
    };

    return (
      <div className={styles.main_container}>
        <div className={styles.main_wrapper}>
          <TextField
            id="filled-hidden-label-small"
            defaultValue=""
            variant="filled"
            size="small"
            label="Search device name"
            onChange={onSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.tree_view}>
            {isLoading ? (
              <p style={{ color: "black" }}>Loading...</p>
            ) : (
              <TreeView
                aria-label="device selection"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={appContext.appdata.map((devices: any) => {
                  return devices._id;
                })}
                sx={{
                  flexGrow: 1,
                  maxWidth: "15.625rem",
                  overflow: "hidden",
                }}
              >
                {deviceData.map((devices: any) => {
                const deviceStatusStyle = devices.status === "active" ? styles.deviceStatusGreen : styles.deviceStatusRed;
                console.log("devicesStatus",deviceStatusStyle)

                return (
                  <Fragment key={devices._id}>
                    <TreeItem
                      nodeId={devices._id}
                      label={
                        <div className={styles.tree_item}>
                        {devices.name}
                        <div className={`${styles.statusIndicator} ${deviceStatusStyle}`}/>
                      </div>
                      
                      }
                    >
                      {devices.parameters.map((signals: any) => {
                        return (
                          <Fragment key={devices.name + signals.id}>
                            <TreeItem
                               sx={{
                                height: 40,
                              }}
                              nodeId={devices.name + signals.type}
                              label={
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkBoxSelectedSignals.includes(
                                        `${devices._id}-${signals.type}`
                                      )}
                                      onChange={(event) =>
                                        onInputCheckboxChangeHandler(
                                          event,
                                          devices._id,
                                          devices.name,
                                          signals.id,
                                          signals.type
                                        )
                                      }
                                    />
                                  }
                                  label={<>{signals.type}</>}
                                  key={signals.id}
                                />
                              }
                            ></TreeItem>
                          </Fragment>
                        );
                      })}
                    </TreeItem>
                  </Fragment>
                );
              })}
              </TreeView>
            )}
          </div>
        </div>
      </div>
    );
  };

  export default DeviceSelection;
