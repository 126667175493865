import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  Box,
  Typography,
  Button,
  FilledInput,
  InputAdornment,
  IconButton,
  dividerClasses,
} from "@mui/material";
import styles from "./login.module.scss";
import axiosClient from "../../common/apis/axiosClient";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../../Auth";

interface LoginProps {}

const Successfull: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const auth = useAuth();
  return (
    <div className={styles.verf}>
      <form>
        <div>
          <div className={styles.title}>
            <Typography
              variant="h2"
              className={styles.verfTxt}
              style={{
                margin: "8rem 0 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              SUCCESSFUL
            </Typography>
            <div></div>
            <Typography
              variant="h5"
              className={styles.verfCaptionTxt}
              style={{
                margin: "0.5rem 0 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Kindly go to the login page
            </Typography>
            <div
              className={styles.new_user}
              style={{
                margin: "1rem 0 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="/auth/login">Back to LOGIN page</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Successfull;
