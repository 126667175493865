export const createReactiveStorage = () => {
    const subscribers = new Set<() => void>();
    const broadCast = () => {
      for (const subscriber of Array.from(subscribers)) {
        subscriber();
      }
    };
    return {
      getItem: (key: string) => localStorage.getItem(key),
      setItem: (key: string, value: string) => {
        localStorage.setItem(key, value);
        broadCast();
      },
      clear: () => {
        localStorage.clear();
        broadCast();
      },
      subscribe: (cb: () => void) => {
        subscribers.add(cb);
        return () => {
          subscribers.delete(cb);
        };
      },
    };
  };
  