import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TextField, Box, Typography, Button, FilledInput, InputAdornment, IconButton, dividerClasses } from "@mui/material";
import styles from "./login.module.scss";
import axiosClient from "../../common/apis/axiosClient";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../../Auth";

interface LoginProps { }

const VerificationLink: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();



  const auth = useAuth()
  return (
    <div className={styles.verf}>
      <form>
        <div>
          <div className={styles.title}>
            <Typography variant="h3" className={styles.verfTxt} style={{ margin: "8rem 0 0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              Verification link is sent to mail
            </Typography>
            <Typography variant="h5" className={styles.verfCaptionTxt} style={{ margin: "0.5rem 0 0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
              Please click on the link to proceed
            </Typography>
          </div>

        </div>
      </form>
    </div>

    



  );
};

export default VerificationLink;
