import { useState, useEffect } from "react";
import styles from "./alerts.module.scss";
import searchBar from "../../shared/assets/icons/searchBar.svg"
import ManageAlert from './manage-alert';
import Table from './table';
import Pagenation from './pagenation'
import AlertTab from './alertTab';
import { TablePagination } from '@mui/material';
import MenuDots from './menuDots';
import axiosClient from '../../common/apis/axiosClient';




const Alerts = () => {


  return (
    <div className={styles.main_container}>
    <div className={styles.wrapper}>
    <div className={styles.materialTable}>
      
    <div><AlertTab /></div>
        {/* <div><ManageAlert /></div> */}
  </div>
  </div>
  </div>
  )
}
export default Alerts




