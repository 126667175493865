import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import styles from "./login.module.scss";
import axiosClient from "../../common/apis/axiosClient";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../../Auth";
import SlideShow from "./slideshow";
import architectere1 from "../../shared/assets/images/architecture1.png";
import device_formatted from "../../shared/assets/images/device_formatted.png";
import slide_1_mob from "../../shared/assets/images/slide_1_mob.png";
import hydrophonics_graphics from "../../shared/assets/images/hydrophonics_graphics.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSetRefreshToken } from "../../hooks/useRefreshToken";

interface LoginProps {}

const images = [
  architectere1,
  device_formatted,
  slide_1_mob,
  hydrophonics_graphics,
];

const Login: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { setToken } = useSetRefreshToken();
  const location = useLocation();

  const redirectPath = location.state?.path || "/dashboard";

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [enteredEmail, setEnteredEmail] = useState<string>("");
  const [enteredEmailTouched, setEnteredEmailTouched] =
    useState<boolean>(false);
  const [enteredPassword, setEnteredPassword] = useState<string>("");
  const [enteredPasswordTouched, setEnteredPasswordTouched] =
    useState<boolean>(false);

  const validateEmail = (inputEmail: string): boolean => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    return regex.test(inputEmail);
  };

  const validatePassword = (password: string): boolean => {
    const trimmedPassword: string = password.trim();
    return trimmedPassword !== "" && trimmedPassword.length >= 8;
  };

  const enteredEmailIsValid: boolean = validateEmail(enteredEmail);
  const entetedEmailInputIsInvalid: boolean =
    !enteredEmailIsValid && enteredEmailTouched;

  const enteredPasswordIsValid: boolean = validatePassword(enteredPassword);
  const enteredPasswordInputIsInvalid: boolean =
    !enteredPasswordIsValid && enteredPasswordTouched;

  let formIsValid: boolean = false;

  if (enteredEmailIsValid && enteredPasswordIsValid) {
    formIsValid = true;
  }

  function getCookieValue(access_token: string): string | null {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === access_token) {
        return value;
      }
    }
    return null;
  }

  const googleAuth = () => {
    const googleAuthURL = `https://app.vandyam.com/api/auth/google`;
    const popup: any = window.open(
      googleAuthURL,
      "blank",
      "width=500,height=600"
    );
  };

  useEffect(() => {
    const tkn = getCookieValue("access_token");
    if (tkn) {
      auth.login(true);
      setToken(tkn);
      localStorage.setItem("authorizationComplete", "true");
      window.close();
    }
  }, []);

  const checkAuthorizationComplete = () => {
    const authorizationComplete = localStorage.getItem("authorizationComplete");
    if (authorizationComplete === "true") {
      localStorage.removeItem("authorizationComplete");
      window.location.reload();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkAuthorizationComplete, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEmail(event.target.value);
  };

  const emailInputBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    setEnteredEmailTouched(true);
  };

  const passwordChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnteredPassword(event.target.value);
  };

  const passwordInputBlurHandler = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    setEnteredPasswordTouched(true);
  };

  const onSuccessSignIn = () => {
    axiosClient
      .post("api/login", {
        email: enteredEmail,
        password: enteredPassword,
      })
      .then((response) => {
        console.log("loginrespData", response);
        setToken(response.data.token);
        if (response.status === 200) {
          console.log("enterd");
          auth.login(response.data.user);

          navigate(redirectPath, { replace: true });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response && error.response.status === 400) {
          toast.error("Invalid email or password");
        } else {
          toast.error("Login failed");
        }
      });
  };

  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.Login}>
        <form>
          <Box className={styles.credBox}>
            <div className={styles.title}>
              <Typography variant="h3" className={styles.loginTxt}>
                Log in
              </Typography>
              <Typography variant="body2" className={styles.captionTxt}>
                Please enter your credentials
              </Typography>
            </div>
            <div className={styles.credentials}>
              <div>
                <Typography variant="h5">Email</Typography>
                <TextField
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  margin="normal"
                  className={styles.emailBox}
                  onChange={emailChangeHandler}
                  onBlur={emailInputBlurHandler}
                  error={entetedEmailInputIsInvalid}
                  helperText={
                    (entetedEmailInputIsInvalid && "Not a valid Email") || " "
                  }
                />{" "}
              </div>
              <div>
                <Typography variant="h5">Password</Typography>
                <TextField
                  id="password"
                  name="password"
                  variant="outlined"
                  margin="normal"
                  className={styles.passBox}
                  onChange={passwordChangeHandler}
                  onBlur={passwordInputBlurHandler}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={enteredPasswordInputIsInvalid}
                  helperText={
                    (enteredPasswordInputIsInvalid && "Not a valid Password") ||
                    " "
                  }
                />
              </div>
            </div>
            <div className="loginBtn" onClick={onSuccessSignIn}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                name="loginBtn"
                className={styles.loginBtn}
              >
                Sign In
              </Button>
            </div>
            <button className={styles.googleSignIn} onClick={googleAuth}>
              <span>SIGN IN WITH GOOGLE</span>
            </button>
            <ToastContainer />
            <div className={styles.forgot_pwd}>
              <a href="/auth/forgot-password">Forgot Password?</a>
            </div>
            <div className={styles.new_user}>
              <a href="/auth/register">New user? Register here</a>
            </div>
          </Box>
        </form>
      </div>
      <div className={styles.loginPageSlides}>
        <SlideShow images={images} />
      </div>
    </div>
  );
};

export default Login;
