import React, { useState, useEffect, useContext, FC } from "react";
import styles from "./trend-plot.module.scss";
import {
  Dialog,
  DialogContent,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { Close, Fullscreen, MoreHoriz, MoreVert } from "@mui/icons-material";
import PlotSettings from "../plot-settings/plot-settings";
import AxisSettings from "../axis-settings/axis-settings";
import { TrendConfigContext } from "../../context/TrendConfigProvider";
import { DeviceDataContext } from "../../context/DeviceDataProvider";
import { AppContext } from "../../context/AppContext";
var Plotly = require("plotly.js/dist/plotly.js");

interface TrendPlotProps {
  trendTitle: string;
}

const TrendPlot: FC<TrendPlotProps> = (props) => {

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255)",
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const [showPlotSettings, setShowPlotSettings] = useState({
    show: false,
    positionX: 1500,
    positionY: 110,
  });
  const [showAxis1Settings, setShowAxis1Settings] = useState({
    show: false,
    positionX: 162,
    positionY: 192,
  });
  const [showAxis2Settings, setShowAxis2Settings] = useState({
    show: false,
    positionX: 162,
    positionY: 192,
  });
  const [showAxis3Settings, setShowAxis3Settings] = useState({
    show: false,
    positionX: 162,
    positionY: 192,
  });
  const [showAxis4Settings, setShowAxis4Settings] = useState({
    show: false,
    positionX: 162,
    positionY: 192,
  });
  const [displayFullScreen, setDisplayFullScreen] = React.useState(false);
  const [axis1Color, setAxis1Color] = useState("#1f77b4");
  const [axis2Color, setAxis2Color] = useState("#ff7f0e");
  const [axis3Color, setAxis3Color] = useState("#771FB4");
  const [axis4Color, setAxis4Color] = useState("#00FF00");
  const [axis1Scale, setAxis1Scale] = useState<string>("linear");
  const [axis2Scale, setAxis2Scale] = useState<string>("linear");
  const [axis3Scale, setAxis3Scale] = useState<string>("linear");
  const [axis4Scale, setAxis4Scale] = useState<string>("linear");

  const trendConfigContext = useContext(TrendConfigContext);
 
  const appContext = useContext(AppContext);

  const layout = {
    autosize: true,
    showlegend: true,
    legend: {
      orientation: "h",
      xanchor: "center",
      x: 0.5,
      y: -0.25,
    },
    margin: { t: 50 },
    xaxis: {
      linewidth: 2,
      tickwidth: 4,
      showgrid: false,
      tickformat: "%I:%M %p<br>%Y-%m-%d", // Include date format
      tickfont: {
        color: "black",
        size: 0,
      },
    },
    yaxis: {
      title: {
        text: "",
        font: {
          color: axis1Color,
          size: 12,
        },
      },
      tickfont: { color: axis1Color },
      titlefont: { color: axis1Color },
      showgrid: false,
      side:"left",
      linewidth: 2,
      tickwidth:4,
      type: axis1Scale,
    },
    yaxis2: {
      title: {
        text: "",
        font: {
          color: axis2Color,
          size: 12,
        },
      },
      tickfont: { color: axis2Color },
      anchor: "x",
      showgrid: false,
      overlaying: "y",
      side: "right",
      linewidth: 2,
      type: axis2Scale,
      
    },

    yaxis3: {
      title: {
        text: "",
        font: {
          color: axis3Color,
          size: 12,
        },
      },
      tickfont: { color: axis3Color },
      titlefont: { color: axis3Color },
      showgrid: false,
      anchor: "free",
      overlaying: "y",
      side:"left",
      linewidth: 3,
      type: axis3Scale,
      position: 0.05
    },
    yaxis4: {
      title: {
        text: "",
        font: {
          color: axis4Color,
          size: 12,
        },
      },
      tickfont: { color: axis4Color },
      anchor: "free",
      showgrid: false,
      overlaying: "y",
      side: "right",
      linewidth: 3,
      type: axis4Scale,
      position: 0.95
    },
  };

  var config = {
    responsive: true,
    scrollZoom: true,
    displaylogo: false,
    displayModeBar: true
  };

  var trace1 = 
  {
    x: trendConfigContext.trendConfig[props.trendTitle].axis1.data.x,
    y: trendConfigContext.trendConfig[props.trendTitle].axis1.data.y,
    type: "scatter",
    mode: "lines+markers",
    // name: "Pressure",
    // line: { shape: "spline" },
    marker: { color: "#1f77b4" },
    connectgaps: true,
  };
  console.log("trace1.y",trace1.y);
  var trace2 = {
    x: trendConfigContext.trendConfig[props.trendTitle].axis2.data.x,
    y: trendConfigContext.trendConfig[props.trendTitle].axis2.data.y,
    type: "scatter",
    mode: "lines+markers",
    // name: "Temprature",
    yaxis: "y2",
    // line: { shape: "spline" },
    marker: { color: "#1f77b4" },
    connectgaps: true,
  };
  var trace3 = {
    x: trendConfigContext.trendConfig[props.trendTitle].axis3.data.x,
    y: trendConfigContext.trendConfig[props.trendTitle].axis3.data.y,
    type: "scatter",
    mode: "lines+markers",
    // name: "Pressure",
    yaxis: "y3",
    // line: { shape: "spline" },
    marker: { color: "#1f77b4" },
    connectgaps: true,
  };
  var trace4 = {
    x: trendConfigContext.trendConfig[props.trendTitle].axis4.data.x,
    y: trendConfigContext.trendConfig[props.trendTitle].axis4.data.y,
    type: "scatter",
    mode: "lines+markers",
    // name: "Temprature",
    yaxis: "y4",
    // line: { shape: "spline" },
    marker: { color: "#1f77b4" },
    connectgaps: true,
  };
  var data = [trace1, trace2, trace3, trace4];

  const plotSettingHandler = (event: any) => {
    console.log(layout);
    setShowPlotSettings((current) => ({
      show: true,
      positionX: event.clientX,
      positionY: event.clientY,
    }));
  };

  const plotSettingHandleClose = () => {
    setShowPlotSettings((current) => ({ ...current, show: false }));
  };

  const open = Boolean(showPlotSettings);
  const id = open ? "simple-popover" : undefined;

  const axis1HandleClick = (event: any) => {
    setShowAxis1Settings((current) => ({
      show: true,
      positionX: event.clientX,
      positionY: event.clientY,
    }));
  };
  const axis1HandleClose = (event: any) => {
    setShowAxis1Settings((current) => ({ ...current, show: false }));
  };

  const openL = Boolean(showAxis1Settings);
  const id1 = openL ? "simple-popover" : undefined;

  const axis2HandleClick = (event: any) => {
    setShowAxis2Settings((current) => ({
      show: true,
      positionX: event.clientX,
      positionY: event.clientY,
    }));
  };
  const axis2HandleClose = (event: any) => {
    setShowAxis2Settings((current) => ({ ...current, show: false }));
  };

  const openR = Boolean(showAxis2Settings);
  const id2 = openR ? "simple-popover" : undefined;

  const axis3HandleClick = (event: any) => {
    setShowAxis3Settings((current) => ({
      show: true,
      positionX: event.clientX,
      positionY: event.clientY,
    }));
  };
  const axis3HandleClose = (event: any) => {
    setShowAxis3Settings((current) => ({ ...current, show: false }));
  };

  const openL2 = Boolean(showAxis3Settings);
  const id3 = openL2 ? "simple-popover" : undefined;

  const axis4HandleClick = (event: any) => {
    setShowAxis4Settings((current) => ({
      show: true,
      positionX: event.clientX,
      positionY: event.clientY,
    }));
  };
  const axis4HandleClose = (event: any) => {
    setShowAxis4Settings((current) => ({ ...current, show: false }));
  };

  const openR2 = Boolean(showAxis4Settings);
  const id4 = openR2 ? "simple-popover" : undefined;

  const fullscreenDisplayHandler = () => {
    setDisplayFullScreen(true);
    // setTimeout(() => {
    //   Plotly.newPlot(`full-plot-${props.trendTitle}`, data, layout, config);
    // });
  };

  const fullScreenCloseHandler = () => {
    setDisplayFullScreen(false);
  };

  const axisColorChangeHandler = (
    trend: string,
    axis: string,
    color: string
  ) => {
    if (axis === "axis1") {
      setAxis1Color(color);
    } else if (axis === "axis2") {
      setAxis2Color(color);
    } else if (axis === "axis3") {
      setAxis3Color(color);
    } else if (axis === "axis4") {
      setAxis4Color(color);
    }
  };

  const axisScaleChangeHandler = (
    trend: string,
    axis: string,
    scale: string
  ) => {
    if (axis === "axis1") {
      setAxis1Scale(scale);
    } else if (axis === "axis2") {
      setAxis2Scale(scale);
    }else if (axis === "axis3") {
      setAxis3Scale(scale);
    }else if (axis === "axis4") {
      setAxis4Scale(scale);
    }
  };

  const plotSettings = () => {
    return (
      <div>
        <PlotSettings
          trend={props.trendTitle}
          positionX={showPlotSettings.positionX}
          positionY={showPlotSettings.positionY}
          id={id}
          open={open}
          showPlotSettings={showPlotSettings.show}
          onClose={plotSettingHandleClose}
        />
      </div>
    );
  };

  const axis1Settings = (axis: string) => {
    return (
      <div>
        <AxisSettings
          positionX={showAxis1Settings.positionX}
          positionY={showAxis1Settings.positionY}
          id={id1}
          open={openL}
          showAxis1={showAxis1Settings.show}
          onClose={axis1HandleClose}
          axis={axis}
          trend={props.trendTitle}
          axisColorChangeHandler={axisColorChangeHandler}
          axisScaleChangeHandler={axisScaleChangeHandler}
        />
      </div>
    );
  };

  const axis2Settings = (axis: string) => {
    return (
      <div>
        <AxisSettings
          positionX={showAxis2Settings.positionX}
          positionY={showAxis2Settings.positionY}
          id={id2}
          open={openR}
          showAxis1={showAxis2Settings.show}
          onClose={axis2HandleClose}
          axis={axis}
          trend={props.trendTitle}
          axisColorChangeHandler={axisColorChangeHandler}
          axisScaleChangeHandler={axisScaleChangeHandler}
        />
      </div>
    );
  };

  const axis3Settings = (axis: string) => {
    return (
      <div>
        <AxisSettings
          positionX={showAxis3Settings.positionX}
          positionY={showAxis3Settings.positionY}
          id={id3}
          open={openL2}
          showAxis1={showAxis3Settings.show}
          onClose={axis3HandleClose}
          axis={axis}
          trend={props.trendTitle}
          axisColorChangeHandler={axisColorChangeHandler}
          axisScaleChangeHandler={axisScaleChangeHandler}
        />
      </div>
    );
  };

  const axis4Settings = (axis: string) => {
    return (
      <div>
        <AxisSettings
          positionX={showAxis4Settings.positionX}
          positionY={showAxis4Settings.positionY}
          id={id4}
          open={openR2}
          showAxis1={showAxis4Settings.show}
          onClose={axis4HandleClose}
          axis={axis}
          trend={props.trendTitle}
          axisColorChangeHandler={axisColorChangeHandler}
          axisScaleChangeHandler={axisScaleChangeHandler}
        />
      </div>
    );
  };



  useEffect(() => {
    Plotly.newPlot(`plot-${props.trendTitle}`, data, layout, config);
  }, []);

  useEffect(() => {
    let data: any = [];
    let plotData = trendConfigContext.trendConfig[props.trendTitle];
    
    const axis1DeviceName = appContext.appdata.filter(
      (device: any) => device?._id === plotData.axis1.device
    )[0]?.name;
  
    const axis2DeviceName = appContext.appdata.filter(
      (device: any) => device?._id === plotData.axis2.device
    )[0]?.name;

    const axis3DeviceName = appContext.appdata.filter(
      (device: any) => device?._id === plotData.axis3.device
    )[0]?.name;
  
    const axis4DeviceName = appContext.appdata.filter(
      (device: any) => device?._id === plotData.axis4.device
    )[0]?.name;
    console.log("plotdata",plotData)


    var trace1 = {
      x: plotData.axis1.data.x,
      y: plotData.axis1.data.y,
      type: "scatter",
      mode: "lines+markers",
      hovertemplate:
      `<b>${axis1DeviceName}</b><br>` +
        "<b>" +
        `${plotData.axis1.signal}` +
        "</b>: %{y:.2f}" +
        "<br><br><b>Time</b>: %{x} " +
        "<extra></extra>",
      name: plotData.axis1.signal,
      // line: { shape: "spline" },
      marker: {
        color: axis1Color,
      },
    };
    console.log("ABCD",plotData);
    var trace2 = {
      x: plotData.axis2.data.x,
      y: plotData.axis2.data.y,
      type: "scatter",
      mode: "lines+markers",
      hovertemplate:
      `<b>${axis2DeviceName}</b> <br>` +
        "<b>" +
        `${plotData.axis2.signal}` +
        "</b>: %{y:.2f}" +
        "<br><br><b>Time</b>: %{x}" +
        "<extra></extra>",
      name: plotData.axis2.signal,
      // line: { shape: "spline" },
      marker: {
        color: axis2Color,
      },
      yaxis: "y2",
    };

    var trace3 = {
      x: plotData.axis3.data.x,
      y: plotData.axis3.data.y,
      type: "scatter",
      mode: "lines+markers",
      hovertemplate:
      `<b>${axis3DeviceName}</b> <br>` +
        "<b>" +
        `${plotData.axis3.signal}` +
        "</b>: %{y:.2f}" +
        "<br><br><b>Time</b>: %{x} " +
        "<extra></extra>",
      name: plotData.axis3.signal,
      // line: { shape: "spline" },
      marker: {
        color: axis3Color,
      },
      yaxis:"y3"
     
    };
    var trace4 = {
      x: plotData.axis4.data.x,
      y: plotData.axis4.data.y,
      type: "scatter",
      mode: "lines+markers",
      hovertemplate:
      `<b>${axis4DeviceName}</b> <br>` +
        "<b>" +
        `${plotData.axis4.signal}` +
        "</b>: %{y:.2f}" +
        "<br><br><b>Time</b>: %{x}" +
        "<extra></extra>",
      name: plotData.axis4.signal,
      // line: { shape: "spline" },
      marker: {
        color: axis4Color,
      },
      yaxis:"y4",
    };
    data = [trace1, trace2, trace3, trace4];
    //axis 1 layout configurations
    if (plotData.axis1.signal === null || plotData.axis1.signal === undefined) {
      layout.yaxis.title.text = "";
    } else {
      const unitString =
        plotData.axis1.unit === null ||
        plotData.axis1.unit === undefined ||
        plotData.axis1.unit === ""
          ? ""
          : ` (${plotData.axis1.unit})`;
      layout.yaxis.title.text = plotData.axis1.signal + unitString;
    }
    layout.yaxis.tickfont.color = axis1Color;
    layout.yaxis.title.font.color = axis1Color;
    layout.yaxis.type = axis1Scale;

    //axis 2 layout configurations
    if (plotData.axis2.signal === null || plotData.axis2.signal === undefined) {
      layout.yaxis2.title.text = "";
    } else {
      const unitString =
        plotData.axis2.unit === null ||
        plotData.axis2.unit === undefined ||
        plotData.axis2.unit === ""
          ? ""
          : ` (${plotData.axis2.unit})`;
      layout.yaxis2.title.text = plotData.axis2.signal + unitString;
    }
    layout.yaxis2.tickfont.color = axis2Color;
    layout.yaxis2.title.font.color = axis2Color;
    layout.yaxis2.type = axis2Scale;


   //axis 3 layout configurations
    if (plotData.axis3.signal === null || plotData.axis3.signal === undefined) {
      layout.yaxis3.title.text = "";
    } else {
      const unitString =
        plotData.axis3.unit === null ||
        plotData.axis3.unit === undefined ||
        plotData.axis3.unit === ""
          ? ""
          : ` (${plotData.axis3.unit})`;
      layout.yaxis3.title.text = plotData.axis3.signal + unitString;
    }
    layout.yaxis3.tickfont.color = axis3Color;
    layout.yaxis3.title.font.color = axis3Color;
    layout.yaxis3.type = axis3Scale;

    //axis 4 layout configurations
    if (plotData.axis4.signal === null || plotData.axis4.signal === undefined) {
      layout.yaxis4.title.text = "";
    } else {
      const unitString =
        plotData.axis4.unit === null ||
        plotData.axis4.unit === undefined ||
        plotData.axis4.unit === ""
          ? ""
          : ` (${plotData.axis4.unit})`;
      layout.yaxis4.title.text = plotData.axis4.signal + unitString;
    }
    layout.yaxis4.tickfont.color = axis4Color;
    layout.yaxis4.title.font.color = axis4Color;
    layout.yaxis4.type = axis4Scale;

    Plotly.react(`plot-${props.trendTitle}`, data, layout, config);
    if (displayFullScreen) {
      setTimeout(() => {
        Plotly.newPlot(`full-plot-${props.trendTitle}`, data, layout, config);
      });
    }
  }, [
    trendConfigContext.trendConfig,
    axis1Color,
    axis2Color,
    axis3Color,
    axis4Color,
    displayFullScreen,
    axis1Scale,
    axis2Scale,
    axis3Scale,
    axis4Scale,
  ]);

  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.title}>
        {`${props.trendTitle === "trend1" ? "Trend 1" :
        props.trendTitle === "trend2" ? "Trend 2" :
        props.trendTitle === "trend3" ? "Trend 3" :
        props.trendTitle === "trend4" ? "Trend 4" : "Unknown Trend"}`}
        </div>
        <div className={styles.plotSettingsEllipses}>
          <IconButton>
            <LightTooltip title="Plot Settings" placement="bottom">
              <MoreVert onClick={plotSettingHandler} aria-describedby={id} />
            </LightTooltip>
          </IconButton>
          <div>{showPlotSettings.show && plotSettings()}</div>
        </div>

        {trendConfigContext.trendConfig[props.trendTitle].axis1.signal && (
          <div className={styles.axis1Ellipses}>
            <IconButton>
              <LightTooltip title="Axis-1 Settings" placement="bottom">
                <MoreHoriz onClick={axis1HandleClick} aria-describedby={id1} />
              </LightTooltip>
            </IconButton>
            <div>{showAxis1Settings.show && axis1Settings("axis1")}</div>
          </div>
        )}

        {trendConfigContext.trendConfig[props.trendTitle].axis2.signal && (
          <div className={styles.axis2Ellipses}>                
            <IconButton>
              <LightTooltip title="Axis-2 Settings" placement="bottom">
                <MoreHoriz onClick={axis2HandleClick} aria-describedby={id2} />
              </LightTooltip>
            </IconButton>
            <div>{showAxis2Settings.show && axis2Settings("axis2")}</div>
          </div>
        )}

{trendConfigContext.trendConfig[props.trendTitle].axis3.signal && (
          <div className={styles.axis3Ellipses}>
            <IconButton>
              <LightTooltip title="Axis-3 Settings" placement="bottom">
                <MoreHoriz onClick={axis3HandleClick} aria-describedby={id3} />
              </LightTooltip>
            </IconButton>
            <div>{showAxis3Settings.show && axis3Settings("axis3")}</div>
          </div>
        )}

        {trendConfigContext.trendConfig[props.trendTitle].axis4.signal && (
          <div className={styles.axis4Ellipses}>                
            <IconButton>
              <LightTooltip title="Axis-4 Settings" placement="bottom">
                <MoreHoriz onClick={axis4HandleClick} aria-describedby={id4} />
              </LightTooltip>
            </IconButton>
            <div>{showAxis4Settings.show && axis4Settings("axis4")}</div>
          </div>
        )}

        <div className={styles.plot} id={`plot-${props.trendTitle}`}></div>
        <div className={styles.fullScreen}>
          <IconButton>
            <div>
              <LightTooltip title="Full Screen" placement="bottom">
                <Fullscreen onClick={fullscreenDisplayHandler} />
              </LightTooltip>
            </div>
          </IconButton>
          <Dialog
            fullScreen
            open={displayFullScreen}
            aria-labelledby="responsive-dialog-title"
          >
            <div className={styles.closeFullScreenButton}>
              <IconButton
                onClick={fullScreenCloseHandler}
                aria-label="close"
                size="large"
              >
                <LightTooltip title="Close" placement="bottom">
                  <Close />
                </LightTooltip>
              </IconButton>
            </div>
            <DialogContent className={styles.fullScreenDialog}>
              {
                <div
                  className={styles.full_screen_plot}
                  id={`full-plot-${props.trendTitle}`}
                ></div>
              }
            </DialogContent>
          </Dialog>
        </div>
      </div>

    </>
  );
};

export default TrendPlot;
